
a.fa 
    text-decoration: none

.fhlb-container 
    padding-left: 45px !important
    padding-right: 45px !important


.summary-box 
    border-top-width: 5px
    border-left-width: 1px
    border-right-width: 1px
    border-bottom-width: 1px
    border-style: solid
    margin: 15px 0
    min-height: 213px
    border-color: lightgrey
    border-top-color: #002F5F

.summary-box > * > .block-ui 
    height: 150px
    

.summary-box-2 
    border-top-width: 5px
    border-left-width: 1px
    border-right-width: 1px
    border-bottom-width: 1px
    border-style: solid
    margin: 15px 0
    min-height: 250px
    overflow-x: auto

.summary-box-table 
    height: 150px
    margin-bottom: 0
    table-layout: fixed

.summary-box-table > tbody > tr > td 
    vertical-align: middle
    padding: 0px !important
    padding-left: 15px !important
    height: 33.33%
    

.summary-box-table.height-25-percent > thead > tr > td,
.summary-box-table.height-25-percent > tbody > tr > td 
    height: 25%

.summary-box-table-2 
    margin-bottom: 0
    height: 180px

.summary-box-header 
    color: #0098DB
    font-family: 'Open Sans'
    font-weight: bold
    padding: 15px 0px 0px 15px
    height: 56px

.summary-box-content 
    color: #6E6E6E

/*Responsive Error Messages*/
.form-group.has-error 
    position: relative

.form-group .help-block-left,
.form-group .help-block 
    background-color: #d52b1e
    border: 1px solid #d52b1e
    padding: 3px 5px
    border-radius: 2px 2px 2px 2px
    height: auto
    margin: 0 auto
    position: relative
    width: calc(100% + 1px)
    text-align: center

.form-group .help-block-left:before,
.form-group .help-block:before 
    border-color: transparent
    border-style: solid
    border-width: 0px 7px 7px
    border-bottom-color: #d52b1e
    content: ""
    position: absolute
    left: calc(50% - 4px)
    right: calc(50% - 4px)
    top: -5px
    margin-left: auto
    margin-right: auto
    

.form-group .help-block-left p,
.form-group .help-block p 
    margin: 0
    font-size: 12px
    line-height: 12px
    color: #fff
    display: table-cell
    vertical-align: middle
    

.form-group.has-error .help-block-left,
.form-group.has-error .help-block 
    display: table

.form-group .help-block-left,
.form-group .help-block 
    display: none


/* begin toast*/

input[type="text"] 
    font-size: 16px
    color: #6E6E6E

input[type="password"] 
    font-size: 16px
    color: #6E6E6E

input[type="email"] 
    font-size: 16px
    color: #6E6E6E

input[type="submit"] 
    font-size: 18px

div#toast-container.validation-popup-id-toast-container.toast-top-center 
    top: 0px

.validation-highlight 
    background-color: #FBEAE9 !important
    border: #a94442 1px solid
    border-radius: 5px
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)



#validation-popup-id .panel > div.panel-heading 
    border-radius: 0px
    height: 42px



#validation-popup-id .panel11 
    background-color: #F8EAE9 !important
    border-radius: 0px

#validation-popup-id .panel-default .panel-heading 
    color: #333 !important
    background-color: #D52B1E !important
    border-radius: 0px

#validation-popup-id-warn .panel-default .panel-heading 
    color: #333 !important
    background-color: #BAA12F !important
    border-radius: 0px
    border-color: #BAA12F !important
/* end toast*/

.border-solid 
    border: 1px solid

.border-solid-light-grey 
    border: 1px solid #ddd

.border-bottom-light-grey 
    border-bottom: 1px solid #ddd

.vertical-center 
    display: -ms-flexbox
    display: flex
    -ms-flex-align: center
    align-items: center

.float-left 
    float: left

.float-right 
    float: right

.float-none 
    float: none

.text-bold 
    font-weight: bold

.width-13-percent 
    width: 13%



.width-23-percent 
    width: 23%


.width-37-percent 
    width: 37%


.width-50-percent 
    width: 50%



.height-30px 
    height: 30px


/* begin flex*/
.flex-container 
    display: -ms-flexbox
    display: flex



.flex-30-percent 
    -ms-flex: 0 0 30%
    flex: 0 0 30%

/*end flex*/

/* begin padding */

.padding-0px 
    padding: 0

.padding-5px 
    padding: 5px !important

.padding-10px 
    padding: 10px !important

.padding-12px 
    padding: 12px

.padding-15px 
    padding: 15px

.padding-20px 
    padding: 20px

.padding-30px 
    padding: 30px

.padding-50px 
    padding: 50px


.padding-70px 
    padding: 70px

.padding-right-0px 
    padding-right: 0px

.padding-right-4px 
    padding-right: 4px !important

.padding-right-5px 
    padding-right: 5px !important

.padding-right-10px 
    padding-right: 10px !important

.padding-right-15px 
    padding-right: 15px !important

.padding-right-20px 
    padding-right: 20px !important

.padding-right-25px 
    padding-right: 25px

.padding-right-30px 
    padding-right: 30px


.padding-right-40px 
    padding-right: 40px

.padding-right-50px 
    padding-right: 50px

.padding-right-65px 
    padding-right: 65px

.padding-bottom-0px 
    padding-bottom: 0px !important

.padding-bottom-3px 
    padding-bottom: 3px

.padding-bottom-5px 
    padding-bottom: 5px

.padding-bottom-10px 
    padding-bottom: 10px

.padding-bottom-15px 
    padding-bottom: 15px

.padding-bottom-20px 
    padding-bottom: 20px

.padding-bottom-30px 
    padding-bottom: 30px

.padding-bottom-30pxi 
    padding-bottom: 30px !important

.padding-bottom-40px 
    padding-bottom: 40px

.padding-bottom-50px 
    padding-bottom: 50px

.padding-bottom-80px 
    padding-bottom: 80px


.padding-bottom-100px 
    padding-bottom: 100px


.padding-top-0px 
    padding-top: 0px !important

.padding-top-1px 
    padding-top: 1px !important

.padding-top-3px 
    padding-top: 3px

.padding-top-4px 
    padding-top: 4px

.padding-top-5px 
    padding-top: 5px !important

.padding-top-6px 
    padding-top: 6px

.padding-top-7px 
    padding-top: 7px

.padding-top-10px 
    padding-top: 10px

.padding-top-15px 
    padding-top: 15px

.padding-top-20px 
    padding-top: 20px

.padding-top-22px 
    padding-top: 22px

.padding-top-30px 
    padding-top: 30px

.padding-top-40px 
    padding-top: 40px

.padding-top-50px 
    padding-top: 50px

.padding-top-60px 
    padding-top: 60px

.padding-top-70px 
    padding-top: 70px

.padding-left-0px 
    padding-left: 0px !important

.padding-left-1px 
    padding-left: 1px !important

.padding-left-5px 
    padding-left: 5px !important

.padding-left-6px 
    padding-left: 6px

.padding-left-7px 
    padding-left: 7px

.padding-left-10px 
    padding-left: 10px !important

.padding-left-12px 
    padding-left: 12px

.padding-left-15px 
    padding-left: 15px !important

.padding-left-18px 
    padding-left: 18px

.padding-left-20px 
    padding-left: 20px !important

.padding-left-25px 
    padding-left: 25px

.padding-left-30px 
    padding-left: 30px

.padding-left-35px 
    padding-left: 35px

.padding-left-40px 
    padding-left: 40px

.padding-left-45px 
    padding-left: 45px

.padding-left-50px 
    padding-left: 50px

.padding-left-55px 
    padding-left: 55px

.padding-left-60px 
    padding-left: 60px

.padding-left-75px 
    padding-left: 75px


.padding-left-90px 
    padding-left: 90px

.padding-left-125px 
    padding-left: 125px

.padding-left-150px 
    padding-left: 150px

.padding-left-right-5px 
    padding-left: 5px !important
    padding-right: 5px !important

.padding-left-38px 
    padding-left: 38px

/* end padding */

/* begin fhlbc-modal */
.modal-width-300px .modal-dialog 
    width: 300px

@media screen and (min-width: 400px) 
    .modal-width-300px .modal-dialog 
        width: 350px
    

.modal-width-325px > .modal-dialog 
    width: 325px

.modal-width-350px > .modal-dialog 
    width: 350px

.modal-width-380px > .modal-dialog 
    width: 380px

.modal-width-650px > .modal-dialog 
    width: 650px

.modal-width-755px > .modal-dialog 
    width: 755px

.modal-width-815px > .modal-dialog 
    width: 815px

.modal-width-1140px > .modal-dialog 
    width: 1140px

.width-1140px-height-650 
    min-width: 1140px
    max-width: 1140px
    min-height: 650px
    max-height: 650px

.modal-content 
    border-radius: 0px
    background-color: white

.modal-hr 
    border-width: 5px 0px 0px 0px
    border-style: solid
    padding-top: 0px
    margin-top: 0px
    margin-bottom: 0px
    border-top-color: #00AE3E

.modal-hr-1 
    border-top-width: 1px
    padding-top: 0px
    margin-top: 0px
    margin-bottom: 0px

div.modal 
    height: 100%
    overflow-x: auto !important
    -ms-overflow-style: auto !important

div.modal-body 
    padding: 20px
    background-color: white

div.modal-dialog 
    display: table
    height: inherit
    margin-top: 0px
    margin-bottom: 0px

div.modal-dialog > div#modal-center 
    display: table-cell
    vertical-align: middle
    

.modal-header 
    border-bottom: none

/* end fhlbc-modal */

.align-body-content-center 
    position: absolute !important
    top: 0
    right: 0
    bottom: 0
    left: 0
    height: 100%
    width: 100%

.vertical-align-outer 
    display: table
    height: inherit
    width: inherit

.width-100-percent 
    width: 100% !important

.height-100-percent 
    height: 100%

.vertical-align-middle-inner 
    display: table-cell
    vertical-align: middle
    width: inherit
    height: inherit


.table-cell-container 
    width: 100%
    height: 100%
    position: relative

.row-height-hack 
    display: table

.col-height-hack 
    display: table-cell
    float: none

/* begin fonts */
.font-size-0 
    font-size: 10px

.font-size-1 
    font-size: 12px

.font-size-2 
    font-size: 14px !important

.font-size-3 
    font-size: 16px !important

.font-size-4 
    font-size: 18px

.font-size-5 
    font-size: 20px

.font-size-6 
    font-size: 22px

.font-size-7 
    font-size: 25px

.font-size-8 
    font-size: 30px

.font-size-9 
    font-size: 11px

.font-size-10 
    font-size: 15px

.font-size-27px 
    font-size: 27px

.font-size-12 
    font-size: 26px

.font-open-sans 
    font-family: 'Open Sans'

.font-open-sans-semibold 
    font-family: 'Open Sans SemiBold'

.font-open-sans-semibold, .text-semibold 
    font-family: 'Open Sans SemiBold'

.font-open-sans-bold 
    font-family: 'Open Sans'
    font-weight: bold

.font-helvetica 
    font-family: Arial, Helvetica, sans-serif

/* end fonts*/

/* font colors - start */

.font-color-gray 
    color: #6E6E6E

.font-color-dark-gray 
    color: #676767

.font-color-green 
    color: #B4B6B3

.font-color-dark-blue 
    color: #002E5E

.font-color-dark-blue-2 
    color: #002F5F

.font-color-charcoal 
    color: #404851

.font-color-dark-charcoal 
    color: #4f4f4f

.font-color-orange 
    color: #D6963B

.font-color-dark-orange 
    color: #CA7700

.font-color-dark-gold 
    color: #B2541A

.font-color-white 
    color: #FFFFFF

.font-color-turquoise 
    color: #0098DB

.font-color-light-grey 
    color: #B5B6B3

.font-color-blue 
    color: #0097DA

/* font colors - end */

/* font margin - start */

.margin-left-6 
    margin-left: 6%


.margin-0px 
    margin: 0px

.margin-20px 
    margin: 20px

.margin-left-200px 
    margin-left: 200px

.margin-right-0px 
    margin-right: 0px !important

.margin-right-20px 
    margin-right: 20px !important


.margin-right-30px 
    margin-right: 30px

.margin-left-minus-30px 
    margin-left: -30px

.margin-left-30px 
    margin-left: 30px !important

.margin-left-0px 
    margin-left: 0px

.margin-left-5px 
    margin-left: 5px

.margin-left-8px 
    margin-left: 8px !important

.margin-left-10px 
    margin-left: 10px

.margin-left-12px 
    margin-left: 12px

.margin-top-9px 
    margin-top: 9px !important

.margin-right-75px 
    margin-right: 75px !important

.width-80px 
    width: 80px !important

.margin-left-15px 
    margin-left: 15px

.margin-left-minus-19pxi 
    margin-left: -19px !important

.margin-left-20px 
    margin-left: 20px !important

.left-margin-30px 
    margin-left: 30px

.margin-right-10px 
    margin-right: 10px

.margin-right-15pxi 
    margin-right: 15px !important

.margin-right-15px 
    margin-right: 15px

margin-left-minus-4px 
    margin-left: -4px

.margin-righ-minus-20px 
    margin-right: -20px

.margin-left-minus-25px 
    margin-left: -25px

.bottom-margin-15px 
    margin-bottom: 15px

.bottom-margin-0px 
    margin-bottom: 0px

.bottom-margin-5px 
    margin-bottom: 5px

.bottom-margin-10px 
    margin-bottom: 10px

.bottom-margin-10px-i 
    margin-bottom: 10px !important

.bottom-margin-20px 
    margin-bottom: 20px

.bottom-margin-25px 
    margin-bottom: 25px

.bottom-margin-28px 
    margin-bottom: 28px

.bottom-margin-30px 
    margin-bottom: 30px

.bottom-margin-40px 
    margin-bottom: 40px

.bottom-margin-50px 
    margin-bottom: 50px

.top-margin-2px 
    margin-top: 2px

.top-margin-10px 
    margin-top: 10px

.margin-top-15px 
    margin-top: 15px

.top-margin-20px 
    margin-top: 20px

.top-margin-minus-20px 
    margin-top: -20px

.top-margin-30px 
    margin-top: 30px

.top-minus-10px 
    top: -10px

/* font margin - end */

/* custom media queries - start */

@media (min-width: 992px) 
    /* text align right on all except xs devices */
    .text-align-right-sm 
        text-align: right
    

    .padding-right-50px-sm 
        padding-right: 50px
    

/* custom media queries - end */

/* Progress bar - start */
form .progress 
    line-height: 15px

.progress 
    display: inline-block
    width: 100%
    border: 3px groove #CCC

.progress div 
    font-size: smaller
    width: 0
    
/* Progress bar - end */

/* background colors - start */
.background-color-mustard 
    background-color: #baa12f

.background-color-dark-blue-1 
    background-color: #002F5F

.background-color-blue 
    background: #e5f4fb

.background-color-blue-2 
    background-color: #66839E

.background-color-gray-1 
    background-color: #FAFBFC

.background-color-gray-2 
    background-color: lightgray

/* background colors - end */

/* fa-exclamation - start */
.fa-exclamation-triangle-color 
    color: white !important
/* fa-exclamation - start */

.margin-left-minus-5px 
    margin-left: -5px

.margin-left-minus-15px 
    margin-left: -15px

.margin-top-minus-10px 
    margin-top: -10px

.text-overflow-ellipsis 
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

/*#region Line Heights */

.line-height-34px 
    line-height: 34px

.line-height-30px 
    line-height: 30px

.line-height-22px 
    line-height: 22px

.line-height-1 
    line-height: 1

.line-height-2 
    line-height: 2

/*#endregion */

/*Og forms placeholder text color*/

::-webkit-input-placeholder  /* Chrome */
    color: #B2C0CF
    line-height: 22px
    font-size: 16px

:-ms-input-placeholder  /* IE 10+ */
    color: #B2C0CF
    font-size: 16px

::-moz-placeholder  /* Firefox 19+ */
    color: #B2C0CF
    font-size: 16px
    opacity: 1

:-moz-placeholder  /* Firefox 4 - 18 */
    color: #B2C0CF
    font-size: 16px
    opacity: 1

.inherit-height 
    min-height: inherit
    max-height: inherit
    height: inherit

.orangedescription 
    display: block
    width: auto
    margin-right: auto
    margin-left: auto
    color: #ca7700
    font-size: 10px

.orangeicon 
    display: block
    padding: 5px
    color: #ca7700
    font-size: 20px
    cursor: pointer

/*#region Borders */

.border-bottom-light-grey 
    border-bottom: 1px solid #e5e5e5

.border-right-light-grey 
    border-right: 2px solid #e5e5e5

/*#endregion */

.fhlb-panel-1 
    padding: 20px
    border-bottom-left-radius: 3px
    border-bottom-right-radius: 3px

.fhlb-panel-body-height-1 
    min-height: 300px

.fhlb-panel-body-height-2 
    min-height: 200px

.fhlb-panel-body-height-3 
    min-height: 250px

.fhlb-panel-body-height-4 
    min-height: 100px

.fhlb-panel-body-height-5 
    min-height: 50px

.panel-header-1 
    background: #B2E0F4
    color: #6E6E6E
    padding: 1px 10px
    border-bottom: 1px solid transparent

.panel-header-2 
    background-color: #EFF8FC
    color: #6E6E6E
    padding: 1px 10px
    border-bottom: 1px solid transparent

.btn-horizontral-margin 
    margin-left: 7.5px
    margin-right: 7.5px

.summary-box-link-text 
    color: #CA7700
    font-weight: bold
    text-decoration: underline

.back-button 
    color: #CA7700
    vertical-align: middle

.back-button-text 
    color: #CA7700
    font-weight: bold
    text-decoration: underline

.back-button-text-underline 
    font-weight: bold
    text-decoration: underline

/*#region Vertical Align */

.vertical-align-top 
    vertical-align: top

.vertical-align-middle 
    vertical-align: middle !important

.vertical-align-bottom 
    vertical-align: bottom

/*#endregion */

/* background colors */

.background-color-1 
    background-color: #B4B6B3

.background-color-2 
    background-color: #6E6E6E

/* end- background colors */

/*begin shared mfa*/

.cancel-button 
    min-width: 140px
    margin: 5px 15px

.mfa-step-instructions 
    background-color: #F9F9F9
    padding: 0px 10px
    margin-bottom: 15px
    padding-top: 5px
    padding-bottom: 5px

.mfa-step-instructions > ul 
    margin-bottom: 0px
    padding-left: 20px
    

.mfa-step-instructions > ul > li 
    margin-top: 12px
    margin-bottom: 12px
        

.popover 
    background-color: #E6DDAE
    color: #6E6E6E

#challenge-auth-step2-click-here + div.popover 
    max-width: 385px

#challenge-auth-step2-click-here + div.popover.top > .arrow:after 
    border-top-color: #E6DDAE
    

#mfaOtpCode.form-control 
    width: 40%

.mfa-hr-2 
    border-top-width: 1px
    padding-top: 0px
    margin-top: 0px
    margin-bottom: 25px
    border-top-style: solid
    border-top-color: gray
    margin-left: 25px
    margin-right: 25px
/*end shared mfa */

/*contact us css*/
#contactTable 
    height: 558px
    border-color: #bce8f1

div#contactInfo 
    height: 558px /*height: 560px*/
    border-left: 0

#contactList.active:after,
.contact-list .active:after
    content: ''
    display: block
    position: absolute
    top: 25px
    left: 100%
    width: 0
    height: 0
    border-color: transparent transparent transparent #0098DB
    border-style: solid
    border-width: 10px

div.contact-body 
    p
        margin: 0px !important
    hr
        margin-top: 15px
        margin-bottom: 15px
        border: 0
        border-top: 1px solid #676767
    a
        color: #CA7700
        text-decoration: underline

/* prevent text from appearing underneath the icon */
input[ctrl-reset-field] 
    padding-right: 19px

/* hide the built-in IE10+ clear field icon */
input[ctrl-reset-field]::-ms-clear 
    display: none
    

input::-ms-clear 
    display: none
/* hide cancel icon for search type */
input[ctrl-reset-field]::-webkit-search-cancel-button 
    -webkit-appearance: none

/* icon styles */
input[ctrl-reset-field] + .fa 
    position: relative
    right: 19px
    color: #C0C0C0
    cursor: default

/* animations for ngAnimate */
input[ctrl-reset-field] + .fa.ng-hide-add 
    display: inline !important
    animation: 0.3s fadeOut
    

input[ctrl-reset-field] + .fa.ng-hide-remove 
    animation: 0.5s fadeIn
    

/*end reset-field*/


/*begin FHLBC Modal*/

.fhlbc-modal-button 
    width: 76px
    height: 37px
    color: #FFFFFF
    background-color: #404851
    font-weight: bold
    font-size: 18px


/*end FHLBC Modal*/

label.select 
    overflow: hidden
    position: relative
    display: block
    padding: 0px
    height: 100%
    margin-bottom: 0px

    
input[type=radio],
input[type=checkbox] 
    border: 0
    clip: rect(0 0 0 0)
    height: 1px
    margin: -1px
    overflow: hidden
    padding: 0
    position: absolute
    width: 1px

input[type=radio] + label,
input[type=checkbox] + label 
    text-align: left
    cursor: pointer
    

input[type=radio] + label > span,
input[type=checkbox] + label > span 
    margin-left: 20px
    display: block
    font-weight: normal
        

input[type=radio] + label:before,
input[type=checkbox] + label:before 
    font-family: FontAwesome
    display: inline-block
    letter-spacing: 10px
    color: #535353
    width: 1.4em
    font-weight: normal
    cursor: pointer
    position: absolute
        

input[type=radio]:checked + label:before,
input[type=checkbox]:checked + label:before,
input[type=checkbox]:indeterminate + label:before 
    letter-spacing: 5px
    

input[type=checkbox]:checked + label:before 
    content: "\f046"
    

input[type=radio]:checked + label:before 
    content: "\f192"
    

input[type=checkbox]:indeterminate + label:before 
    content: "\f147"
    

input[type=checkbox] + label:before 
    content: "\f096"
    

input[type=radio] + label:before 
    content: "\f10c"
    

input[type=radio]:disabled + label:before 
    cursor: not-allowed
    color: lightgrey
    


input[type=radio]:focus + label + span,
input[type=checkbox]:focus + label + span 
    font-weight: bold
    

.text-color-red 
    color: red

.text-color-green 
    color: green

.uselinebreaks 
    white-space: pre-line

.inline-help-icon 
    color: #CA7700
    font-size: 22px
    line-height: 23px

.inline-help-text 
    color: #CA7700
    font-size: 9px
    line-height: 13px
    margin-top: -10px

.help-tip 
    position: absolute
    top: 0px
    right: -13px

.help-tip > .tooltip 
    left: -195px !important
    

.tooltip.in 
    opacity: initial

.tooltip.top .tooltip-arrow 
    bottom: 0px !important
    margin-bottom: -5px !important
    border-width: 10px 4px 0
    border-top-color: #CA7700

.tooltip.bottom > .tooltip-arrow:after 
    border-bottom-color: white
    border-top-color: transparent
    border-right-color: transparent
    border-left-color: transparent
    content: ""
    border-width: 0 3px 7px
    position: absolute
    border-style: solid
    margin-top: 6px
    margin-left: -3px

.tooltip.bottom .tooltip-arrow 
    top: -4px !important
    border-width: 0px 4px 10px
    border-bottom-color: #CA7700

.tooltip.top-left > .tooltip-arrow:after,
.tooltip.top > .tooltip-arrow:after,
.tooltip.top-right > .tooltip-arrow:after 
    border-top-color: white
    border-bottom-color: transparent
    border-right-color: transparent
    border-left-color: transparent
    content: ""
    border-width: 7px 3px 0
    position: absolute
    border-style: solid
    margin-top: -12px
    margin-left: -3px

.tooltip.top-right .tooltip-arrow 
    bottom: -4px !important
    left: 5px
    margin-bottom: -5px
    border-width: 10px 4px 0
    border-top-color: #CA7700

.tooltip-inner 
    max-width: 215px
    min-width: 215px
    padding: 3px 5px
    color: black
    text-align: center
    background-color: white
    border-radius: 4px
    border: 2px solid #CA7700


.progress-striped .progress-bar,
.progress-bar-striped 
    background-size: 10px 10px

.table-striped > tbody > tr:nth-of-type(odd) > td 
    background-color: #eff7f9

.SRT_table-striped_odd 
    background-color: #eff7f9 !important

.SRT_table-striped_even 
    background-color: #fff !important

.underline 
    text-decoration: underline

.back-to-top:hover 
    cursor: pointer

.pointer-cursor:hover 
    cursor: pointer

.underline-on-hover:hover 
    text-decoration: underline

.text-decoration-underline 
    text-decoration: underline

.link-with-icon,
.link-with-icon:hover 
    text-decoration: none

.link-with-icon:hover > span + span 
    text-decoration: underline
    

.text-color-comments 
    color: #949495

.btn-width-128px 
    width: 128px


.padding-top-23px 
    padding-top: 23px

.back-to-top 
    font-size: 16px
    font-family: 'Open Sans'
    color: #CA7700
    font-weight: bold
    float: right

.back-to-top a 
    text-decoration: underline
    




.next-loan 
    font-size: 16px
    font-family: 'Open Sans'
    color: #CA7700
    font-weight: bold
    /*float: right*/

.next-loan a 
    text-decoration: underline
    

.next-button 
    vertical-align: middle

.next-button-text 
    font-weight: bold
    text-decoration: underline

.previous-loan 
    font-size: 16px
    font-family: 'Open Sans'
    color: #CA7700
    font-weight: bold
    /*float: right*/

.previous-loan a 
    text-decoration: underline
    


.previous-buttonlink 
    vertical-align: middle

.previous-buttonlink-text 
    font-weight: bold
    text-decoration: underline

.margin-bottom-5px 
    margin-bottom: 5px

.margin-bottom-25px 
    margin-bottom: 25px

.margin-left-minus-3px 
    margin-left: -3px

.margin-left-4px 
    margin-left: 4px

.margin-left-40px 
    margin-left: 40px !important

.margin-right-minus-30 
    margin-right: -30px

.margin-right-minus-30pxi 
    margin-right: -30px !important

.height-300px 
    height: 300px

.padding-bottom-107px 
    padding-bottom: 107px

.padding-top-110px 
    padding-top: 110px

.padding-top-100px 
    padding-top: 100px


.row.is-flex 
    display: -ms-flexbox
    display: flex
    -ms-flex-wrap: wrap
    flex-wrap: wrap

.row.is-flex > [class*='col-'] 
    display: -ms-flexbox
    display: flex
    -ms-flex-direction: column
    flex-direction: column
    

.is-flex .box 
    background: none
    position: static


.font-size-9px 
    font-size: 9px !important

.error-info-block,
.mfa-error-header 
    color: #FFFFFF !important
    font-family: 'Open Sans' !important
    background-color: #D52B1E !important
    margin-left: auto
    margin-right: auto
    padding-top: 8px
    padding-bottom: 8px



.height-50px 
    height: 50px

.btn 
    font-weight: bold

.btn-default .has-error .input-group-addon 
    cursor: not-allowed
    filter: alpha(opacity=65)
    box-shadow: none
    opacity: .65

.btn-default 
    color: #FFFFFF
    background-color: #666666
    border-color: #666666

.btn-green 
    color: #ffffff
    background-color: #00AF3F
    border-color: #00AF3F

.btn-green:hover,
.btn-green:focus 
    color: #000000
    background-color: #e5f7eb
    border-color: #adadad
    

.btn-success:hover,
.btn-success:focus 
    color: #000000 !important
    background-color: #e5f7eb !important
    border-color: #adadad !important

.btn-red 
    color: #ffffff
    background-color: #D52B1E
    border-color: #D52B1E

.odd-row 
    background-color: #EFF8FC

.even-row 
    background-color: #FFFFFF

.text-align-left-sm 
    text-align: left

.pointer 
    cursor: pointer !important

.padding-bottom-35px 
    padding-bottom: 35px

.margin-left-50px 
    margin-left: 50px

.sort-container:hover:before 
    content: "\f0dc" !important

.sort-container.empty:hover:before 
    content: "\00a0" !important

.sort-container 
    padding-bottom: 3px
    text-transform: uppercase

.sort-container:before 
    font-family: "FontAwesome"
    content: "\00a0"
    display: block
    margin-bottom: 3px
    text-align: center
    width: 100%
    


.min-width-128px 
    min-width: 128px

.padding-0px 
    padding: 0px !important

.uib-day > .btn 
    background-color: #0097DA
    border-color: #0097DA
    color: white

.uib-day > .btn 
    border-color: white
    border-radius: 0

.uib-daypicker > thead > tr:nth-child(2) > th 
    color: white

.uib-daypicker > tbody > tr > td > .active 
    background-color: #b1dcf0
    color: black !important

.uib-datepicker .uib-year > button:disabled,
.uib-datepicker .uib-month > button:disabled,
.uib-datepicker .uib-day > button:disabled 
    background-color: #B5B6B3
    color: #333 !important
    border-radius: 0px

.uib-datepicker .uib-year > button,
.uib-datepicker .uib-month > button,
.uib-datepicker .uib-day > button 
    background-color: #F9F9F9
    color: #333 !important
    border-radius: 0px

.uib-datepicker .btn-info,
.uib-datepicker .btn-info,
.uib-datepicker .btn-info:active,
.uib-datepicker .btn-info.active,
.uib-datepicker thead > tr:nth-child(2) > th,
.uib-datepicker .uib-title,
.uib-datepicker .uib-left,
.uib-datepicker .uib-right,
.uib-datepicker .uib-title:hover,
.uib-datepicker .uib-left:hover,
.uib-datepicker .uib-right:hover,
.uib-datepicker .uib-title:disabled,
.uib-datepicker .uib-left:disabled,
.uib-datepicker .uib-right:disabled 
    background-color: #0097DA !important
    border-color: #0097DA !important
    color: white !important
    border-radius: 0px
    opacity: 1

.uib-datepicker 
    background-color: white

.uib-datepicker-popup 
    z-index: 1001

.header-text 
    color: #002F5F !important 
    font-family: Arial, Helvetica, sans-serif
    line-height: 27px

.display-none 
    display: none

.margin-bottom-1px 
    margin-bottom: 1px

.border-right-radius-0px 
    border-top-right-radius: 0px !important
    border-bottom-right-radius: 0px !important

.border-top-left-radius-4px 
    border-top-left-radius: 4px !important

.border-bottom-left-radius-4px 
    border-bottom-left-radius: 4px !important

.text-transform-none 
    text-transform: none !important

.font-weight-bold 
    font-weight: bold !important

.word-break-all 
    word-break: break-all

.break-all-word 
    word-break: break-all

/*#region toaster */

.toast-message a:hover 
    color: #CA7700 !important

.toast-message a 
    color: #CA7700 !important

/*#endregion toaster */

/*#region ui-select-bootstrap */
.ui-select-bootstrap 
    padding: 0px !important
    border: none

.ui-select-bootstrap .ui-select-match:focus,
.ui-select-bootstrap.form-control:focus 
    box-shadow: none
    border: none
    outline: 0px
    

.ui-select-bootstrap .ui-select-match > span.btn.btn-default:focus 
    border-color: #66afe9
    

.ui-select-bootstrap .ui-select-match > span.btn.btn-default 
    background: white !important
    color: #6E6E6E !important
    font-weight: normal !important
    border-color: #ccc
    



.ui-select-bootstrap .ui-select-choices .dropdown-menu > li > a 
    background: white !important
    padding: 2px 4px 2px 12px !important
    color: black
    

.ui-select-bootstrap .ui-select-choices-row > span:hover,
.ui-select-bootstrap .ui-select-choices-row > span:focus 
    background: #66afe9 !important
    color: white !important
    border: none !important
    font-family: 'Open Sans'
    outline: none
    

.ui-select-bootstrap .ui-select-dropdown.dropdown-menu > li > a:hover 
    font-weight: normal !important
    color: white !important
    font-family: 'Open Sans'
    border: none !important
    outline: none
    background: #0097DA !important
    

.ui-select-bootstrap .ui-select-dropdown.dropdown-menu 
    border: 1px #0097DA solid
    background: white !important
    

.ui-select-bootstrap .ui-select-match-text 
    overflow: hidden
    width: 95% !important
    
/*#endregion ui-select-bootstrap */

.padding-right-7px 
    padding-right: 7px

.no-decoration > a:hover,
.no-decoration > a:active,
.no-decoration > a:focus,
.no-decoration > a:visited 
    text-decoration: none

.left-13px 
    left: 13px

.flex-vertically 
    min-height: 100% /* set height as you need */
    display: -ms-flexbox
    display: flex
    -ms-flex-direction: column
    flex-direction: column

.flex-vertically-to-bottom 
    margin-top: auto

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
    /* IE10+ CSS styles go here */
    .flex-vertically 
        height: 100%
        display: -ms-flexbox
    

.padding-bottom-60px 
    padding-bottom: 60px

.box-shadow-none 
    box-shadow: none

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn 
    cursor: not-allowed
    filter: alpha(opacity=65)
    box-shadow: none
    opacity: 1

.width-auto 
    width: auto !important

.error-panel-scroll 
    max-height: 200px !important
    overflow-y: auto !important

.background-color-white 
    background-color: white

.background-color-gray 
    background-color: gray !important

/*pointer event start*/
.pointer-events-none 
    pointer-events: none
    opacity: 0.4
/*pointer event end*/

.cursor-not-allowed 
    cursor: not-allowed

.dropdown-submenu-arrow-right 
    margin-right: 0px !important

li[class="nav-item"] > a 
    padding-left: 0px !important
    padding-right: 0px !important

li[class="nav-item active"] > a 
    padding-left: 0px !important
    padding-right: 0px !important