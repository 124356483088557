


div#main-canvas
    max-width: 1280px
    margin-left: auto
    margin-right: auto
    background-color: white

/*pbi#119086 -- Added css to fix header on the Interactive LPR if Loan source is batch */
.fix-to-top
    position: fixed
    top: 0px
    z-index: 1
    background-color: white
    width: 1160px

/*pbi#119086 -- Added css to fix header on the Interactive LPR if Loan source is batch */
@media only screen and (max-width: 1366px), (-webkit-min-device-pixel-ratio:0)
    .fix-to-top
        width: 86.74%

@media only screen and (max-width: 3840px)
    .fix-to-top
        width: 1160px

@media only screen and (max-width: 1366px), (-ms-high-contrast: active), (-ms-high-contrast: none)
    .fix-to-top
        width: 73.1em

@media only screen and (max-width: 1280px)
    .fix-to-top
        width: 89%

@media only screen and (max-width: 800px)
    .fix-to-top
        width: 82%

@media only screen and (max-width: 1152px)
    .fix-to-top
        width: 80%



#mainbody
    min-height: 480px

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td
    padding-top: 4px
    padding-bottom: 4px
    padding-left: 20px

.wordWrap
    word-wrap: break-word

.exhibit-tr
    background-color: #b1dcf0

.font-white
    color: white

.top-header
    min-height: 145px

#top-header
    margin: 20px 50px 0 0

/*fhlb colors*/

.fhlb-blue-1
    background-color: #0097DA

.fhlb-blue-2
    background-color: #E5F3FB

.fhlb-font-color-2
    color: #CA7700

.fhlb-blue-font
    color: #0098DB
    font-size: 18px
    line-height: 24px

/*end - fhlb colors*/

.panel-title-custom > a:before
    font-family: "Glyphicons Halflings"
    content: "\2212"

.panel-title-custom > a.collapsed:before
    content: "\002b"

.panel-title-custom-faq > div > a:before
    font-family: "Glyphicons Halflings"
    content: "\2212"
    color: #676767

.panel-title-custom-faq > div > a.collapsed:before
    content: "\002b"
    color: #676767
    text-decoration: none

.panel-title-custom-faq > div > a:hover,
.panel-title-custom-faq > div > a:active,
.panel-title-custom-faq > div > a:focus,
.panel-title-custom-faq > div > a:visited
    text-decoration: none

.panel-title > a:hover,
.panel-title > a:active,
.panel-title > a:focus,
.panel-title > a:visited
    text-decoration: none

.two-col-table
    width: 50%
    border-top: none

.table-top-border
    border-top: solid
    border-top-width: thin
    border-top-color: #dddddd

.panel-no-border
    border: none

/*footer nav*/
div#footer-nav
    padding: 0

div#footer-nav ul li a
    color: #0097DA
    background-color: #E5F3FB
    font-weight: bold
    white-space: nowrap
/*end footer nav*/

/*footer nav 2*/
div#footer-nav-2
    padding: 5px 0 5px 0
    color: white
    background-color: #0097DA
    font-weight: bold
/*end footer nav 2*/

.logout-button
    color: #0097DA
    background-color: #E5F3FB
    font-weight: bold
    padding: 5px 10px !important
    margin-top: 5px


a:hover
    cursor: pointer

.margin-list-25
    margin-bottom: 25px

.margin-list-20
    margin-bottom: 20px

[ng\:cloak], [ng-cloak], .ng-cloak
    display: none

.panel-title > a
    outline: 0

.text-color
    color: #7a7a7a

.exhibit-table-striped > tbody > tr:nth-of-type(even) > td
    background-color: #eff7f9

.exhibit-table-group-striped > tbody:nth-of-type(even) > tr > td
    background-color: #eff7f9

.resources-font
    color: #777
    text-decoration: underline
    font-family: 'Open Sans'

.resources-font > a
    text-decoration: underline

.resources-hyperlink
    color: #CA7700
    text-decoration: underline


.glossary-panel-heading
    background-color: #B2E0F4

.glossary-panel
    max-height: 1000px
    min-height: 150px
    width: 100%
    color: #6E6E6E
    line-height: 1.3
    overflow-y: auto
    overflow-x: hidden
    background-color: #eff7f9

.glossary-panel-padding
    min-height: 43px
    padding: 8px 19px 7px 12px

.font-home-error
    color: #ff0000
    font-family: 'Open Sans'
    font-style: italic
/*Batch 2.0*/
.btn-custody-report
    display: inline-block
    padding: 0 3px 0 3px
    margin-bottom: 0
    font-weight: bold
    line-height: 1.4
    text-align: center
    white-space: nowrap
    vertical-align: middle
    -ms-touch-action: manipulation
    touch-action: manipulation
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    background-image: none
    border: 1px solid transparent
    border-radius: 3px

.btn-custody-report-csv
    background-color: #CA7700
    color: white

.btn-custody-report-csv:hover
    background-color: #23527c
    color: white !important

.btn-custody-report-pdf
    color: #CA7700
    border-color: #CA7700

.btn-custody-report-pdf:hover
    border-color: #23527c

.btn-field-description
    color: #B19401
    border-color: #B19401
    font-family: 'Open Sans SemiBold'

.btn-field-description:hover,
.btn-field-description:focus
    border-color: #23527c

.table-padded > thead > tr > th,
.table-padded > tbody > tr > th,
.table-padded > tfoot > tr > th,
.table-padded > thead > tr > td,
.table-padded > tbody > tr > td,
.table-padded > tfoot > tr > td
    padding-top: 6px
    padding-bottom: 6px

.table-padded-left-right-3 > thead > tr > th,
.table-padded-left-right-3 > tbody > tr > th,
.table-padded-left-right-3 > tfoot > tr > th,
.table-padded-left-right-3 > thead > tr > td,
.table-padded-left-right-3 > tbody > tr > td,
.table-padded-left-right-3 > tfoot > tr > td
    padding-left: 3px !important
    padding-right: 3px !important
    vertical-align: middle !important
    line-height: 16px

.upload-file-row-padding
    padding: 8px 0px

.upload-file-border
    border: 1px solid #ddd

.upload-file-border:nth-child(n+2)
    margin-top: -1px

.feedback-row
    padding: 10px 0

.feedback-header
    color: #002F5F
    font-family: Helvetica
    width: 100%

.feedback-header-content
    color: #6E6E6E
    margin-right: -5px


.border-radius-5px-5px-0-0
    border-radius: 5px 5px 0 0

.feedback-box-header
    background: #B2E0F4
    color: #6E6E6E
    padding: 1px 10px
    border-bottom: 1px solid transparent
    border-top-left-radius: 3px
    border-top-right-radius: 3px

.feedback-header-border
    border: 1px solid #B2C0CF
    padding: 20px
    border-bottom-left-radius: 3px
    border-bottom-right-radius: 3px

.feedback-column-left
    vertical-align: middle
    color: #6E6E6E

.feedback-column-right
    text-align: left
    vertical-align: middle

.feedback-display-font
    color: #0098DB !important
    display: block

.feedback-display-text-color
    color: #0098DB !important

.feedback-form-content-align-top
    vertical-align: top

.feedback-form-button
    color: #FFFFFF
    line-height: 1.3
    background-color: #00AF3F

#feedbackFormCategory
    height: 36px

.text-lighter
    color: #6E6E6E

.panel-title
    height: 20px

.panel-title-print
    font-size: 15px !important

.odd
    background-color: #ffffff

.odd-row
    background-color: #ffffff

.even-row
    background-color: #E5F4FB

.even
    background-color: #eff7f9

.mcarchivedheader
    background-color: #0097da

.mcarchivedtext
    color: white

.fhlbc-modal-footer
    border-top: 1px solid #e5e5e5

.modal-height
    height: 400px
    overflow-y: auto
    padding-top: 0

.modal-height-reports
    max-height: 400px
    overflow-y: auto
    padding-top: 0

.text-align-top
    vertical-align: text-top

.border-dot
    border-bottom: dotted
    border-bottom-width: 1px

div#fieldDescriptionsMCDetail > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body,
div#fieldDescriptionsActiveMCSummary > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body,
div#fieldDescriptionsPoolDetail > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body
    padding-bottom: 0

div#mcDetailArchivePFI > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body,
div#poolDetailArchive > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body,
div#activeMcSummaryArchive > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body
    padding: 0

div#mcDetailArchivePFI > div.modal-dialog > div#modal-center > div.modal-content > div.modal-hr,
div#poolDetailArchive > div.modal-dialog > div#modal-center > div.modal-content > div.modal-hr,
div#activeMcSummaryArchive > div.modal-dialog > div#modal-center > div.modal-content > div.modal-hr
    border-width: 0

#pricing-contact-modal .modal-hr,
#dc-reduction-modal .modal-hr,
#dc-extension-modal .modal-hr,
#ContactList .modal-hr,
#modalPrintMessage .modal-hr,
#dc-list .modal-hr,
#mc-list .modal-hr,
#lpr-list .modal-hr,
#fieldDescriptionsPoolDetail .modal-hr,
#fieldDescriptionsMCDetail .modal-hr,
#fieldDescriptionsActiveMCSummary .modal-hr
    border-color: #4CB6E5

#extendDeliveryCommitmentDisplayPanel .control-label,
#extendDeliveryCommitmentPanel .control-label
    color: #676767

.dc-report-table
    overflow: auto
    max-height: 350px
    border-bottom: 1px solid #ddd

.report-table
    overflow: auto
    border-bottom: 1px solid #ddd

div#dc-list > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body div#dc-list-placeholder,
div#mc-list > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body div#mc-list-placeholder,
div#lpr-list > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body div#lpr-list-placeholder
    min-height: 250px

div#dc-list > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body div#dc-list-placeholder > table,
div#mc-list > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body div#mc-list-placeholder > table,
div#lpr-list > div.modal-dialog > div#modal-center > div.modal-content > div.modal-body div#lpr-list-placeholder > table
    margin-bottom: 0px

#pfiBusinessStructureModal .modal-hr,
#extendDcTransNotCompletedId .modal-hr,
#cancelDcModalId .modal-hr,
#cancelReviewReduceDcModalId .modal-hr,
#reduceDcTransNotCompletedId .modal-hr,
#cancelReviewExtendDcModalId .modal-hr,
#createDcTransNotCompletedId .modal-hr,
#cancelReviewCreateDcModalId .modal-hr,
#cancelCreateDcModalId .modal-hr,
#DuplicateDCModal .modal-hr,
#cancelCreateBeDcModalId .modal-hr,
#current-pricing-restricted-products-warning-modal .modal-hr
    border-color: #c80000 !important



.dc-warning-popup
    color: #6E6E6E
    font-size: 20px
    font-family: 'Helvetica'
    font-weight: bold

.table-padded-9 > thead > tr > th,
.table-padded-9 > tbody > tr > th,
.table-padded-9 > tfoot > tr > th,
.table-padded-9 > thead > tr > td,
.table-padded-9 > tbody > tr > td,
.table-padded-9 > tfoot > tr > td
    padding-top: 9px
    padding-bottom: 9px

/*Navigation Menu*/
.marginBottom-0
    margin-bottom: 0

.dropdown:hover > .dropdown-menu
    display: block

.dropdown-submenu:hover
    background-color: #E5F3FB

.dropdown-submenu:hover > a
    text-decoration: none
    background-color: #E5F3FB
    color: #002F5F
    font-family: 'Open Sans SemiBold'

.nav > li.dropdown:hover
    background: #0097DA

.nav > li.dropdown:hover > a
    color: #002F5F

.dropdown-submenu
    position: relative

.dropdown-submenu > .dropdown-menu
    top: 0
    left: 100%
    margin-top: -0px
    margin-left: -0px

.dropdown-submenu-arrow-right
    display: block
    content: " "
    float: right
    width: 0
    height: 0
    border-color: transparent
    border-style: solid
    border-width: 5px 0 5px 5px
    border-left-color: white
    margin-top: 5px
    margin-right: -10px

.dropdown-submenu-arrow-left
    display: block
    content: " "
    float: left
    width: 0
    height: 0
    border-color: transparent
    border-style: solid
    border-width: 5px 5px 5px 0
    border-right-color: white
    margin-top: 5px
    margin-right: 10px
    padding-right: 0px

.dropdown-submenu:hover .dropdown-submenu-arrow-left
    border-right-color: #002F5F !important

.dropdown-submenu:hover .dropdown-submenu-arrow-right
    border-left-color: #002F5F !important

.additional > a:after
    display: none

.dropdown-submenu:hover > a:after
    border-left-color: #002F5F

.dropdown-submenu.pull-left
    float: none

.dropdown-submenu.pull-left > .dropdown-menu
    left: -100%
    margin-left: 10px

.nav-justified > .dropdown .dropdown-menu
    width: 100%

.nav-justified > .dropdown .dropdown-menu .dropdown-submenu .dropdown-menu
    left: 100%
    margin-top: -35px !important
    width: auto
    border-radius: 0 !important

.dropdown-menu-left
    right: 100%
    left: -100% !important

.nav-justified > li > a > span
    text-transform: uppercase

.navbar > .nav-justified > li.active > a > span
    border-top: 5px solid #002F5F
    top: 0
    padding-top: 6px

#footer-nav > .nav-justified > li.active > a > span
    border-bottom: 5px solid #002F5F
    bottom: 0
    padding-bottom: 6px

.navbar
    border-radius: 0
    border: 0
    background-color: #E5F3FB
    font-family: 'Open Sans'
    font-weight: bold
    min-height: inherit
    margin-bottom: 0

.nav-justified > li > a
    text-decoration: none

div#footer-nav > ul > li > a:hover
    color: #002F5F

.nav-justified > li.active > a
    color: #002F5F

.dropdown-menu
    margin: 0 !important
    border: 0 !important
    border-radius: 0 !important
    background-color: #0097DA !important
    padding: 0 !important
    font-family: 'Open Sans'
    min-width: 100% !important

.nav > li > a:focus
    background-color: transparent
    color: #337ab7

.nav > li > a:hover
    text-decoration: none
    background-color: #0097DA
    color: #002F5F

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus
    background-color: #0097DA
    color: #002F5F

li.dropdown.open > ul.dropdown-menu > li.dropdown.open > a
    color: #002F5F
    background: #E5F3FB
    font-family: 'Open Sans Semibold'

.dropdown-menu > li > a:focus
    color: #002F5F !important
    background-color: #E5F3FB !important

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus
    text-decoration: none
    background-color: #E5F3FB
    color: #002F5F
    font-family: 'Open Sans SemiBold'

.dropdown-menu > li > a
    padding: 10px 7px
    color: white
    white-space: normal

.panel-default > .panel-heading
    background-color: #b1dcf0

.batch-reports-scroll
    overflow-y: scroll
    max-height: 460px

@media (min-width: 992px)
    .override-sm-bootstrap-left-margin-15pct
        margin-left: 15%

.min-width
    width: 125px

.batch-accordion-color
    background-color: #b1dcf0

.batch-reports-progress-bar
    background-color: white
    border-color: white
    width: 120px
    text-align: center
    border-radius: 3px

.batch-process-color
    color: #baa12f

.batch-completed-color
    color: #24af51
/*batch 2.0*/
.batch-error-color
    color: red

.batch-reports-informational-error-message
    border: 1px solid #baa12f
    padding-left: 0px
    padding-right: 0px

.exhibits-header-font
    color: #505050
    font-family: 'Open Sans'
    font-weight: bold


.exhibits-text-font
    color: #676767
    font-family: 'Open Sans'

.exhibits-subgroup-text-font
    color: #6E6E6E
    font-family: 'Helvetica'
    font-weight: bold

.exhibits-seperator
    padding-left: 17px
    color: #0098DB
    width: 1px
    border-style: initial


.servicing-seperator
    color: #0098DB
    padding-left: 35px

.exhibit-padding
    padding-top: 12px !important
    padding-left: 0 !important

.table-padded-4 > thead > tr > th,
.table-padded-4 > tbody > tr > th,
.table-padded-4 > tfoot > tr > th,
.table-padded-4 > thead > tr > td,
.table-padded-4 > tbody > tr > td,
.table-padded-4 > tfoot > tr > td
    padding-top: 4px
    padding-bottom: 4px

.table-width-100
    width: 100%

.table-width-100px
    width: 100px

.table-width-30px
    width: 30px

.hr-border
    margin-top: 0
    margin-bottom: 0
    border-top: 2px solid #B2E0F4


.ng-cloak
    display: none !important

#messages > .popover
    top: -30px !important
    width: 300px !important
    border-radius: 0px !important
    padding: 0px

#messages > .popover.bottom > .arrow:after
    border-bottom-color: #002F5F

#messages:hover > .popover-content
    background-color: red

#messages .popover-content
    padding: 0px 14px


#expandanchor:hover
    text-decoration: none
    color: black

#collapseanchor:hover
    text-decoration: none
    color: black

#batchdetailanchor:hover
    text-decoration: none
    color: black

#batcherroranchor:hover
    text-decoration: none
    color: black

.batchreports-anchor
    text-decoration: none
    color: black

.batch-reports-dropdown-label
    overflow: inherit !important
    position: relative
    display: inline-block !important

.batch-reports-dropdown
    color: #0097DA
    padding-right: 12px
    line-height: 1.42857143
    border: 1px solid #B2C0CF
    border-radius: 4px
    height: 34px
    -webkit-appearance: none
    -moz-appearance: none
    background-position-x: 40px
    width: 60px
    padding-left: 10px
    -webkit-appearance: none
    -moz-appearance: none

.other-doc-dropdown
    color: #676767
    padding-right: 12px
    line-height: 1.42857143
    border: 1px solid #B2C0CF
    border-radius: 4px
    height: 34px
    -webkit-appearance: none
    -moz-appearance: none
    background-position-x: 40px
    width: 241px
    padding-left: 10px
    -webkit-appearance: none
    -moz-appearance: none
    background-color: white !important

.batch-link-color
    color: #cf8928 !important

.batch-link-color:hover
    color: #23527c !important

.padding-left-70px
    padding-left: 70px !important

.padding-left-50px
    padding-left: 50px !important

.padding-left-85px
    padding-left: 85px !important

/*batch 2.0*/
.min-height-28px
    min-height: 28px !important

.border-top
    border-top: 2px solid #ddd !important

.margin-left-minus-95px
    margin-left: -95px

.feedbackform-modal-button
    border: 1px solid #00AF3F
    border-radius: 4px
    color: #FFFFFF
    background-color: #00AF3F

.background-panel-header
    background-color: #B2E0F4

.text-align-right
    text-align: right

.text-align-left
    text-align: left

.batch-reports-date-textcolor
    color: #6E6E6E

.upload-header
    color: #002F5F
    font-family: Helvetica
    width: 100%

.upload-text-color
    color: #6E6E6E

.dc-report-header,
.upload-header-box
    background: #B2E0F4
    color: #676767
    padding: 1px 10px
    border-bottom: 1px solid transparent
    border-top-left-radius: 3px
    border-top-right-radius: 3px
    line-height: 22px

.upload-header-border
    border: 1px solid #D9D9D9



.upload-header-border .description
    padding-left: 20px
    padding-right: 30px

.faqs-questions
    color: #676767
    line-height: 1.2

.upload-text-align
    padding-top: 56px
    padding-bottom: 40px

.panel-no-bottom-margin
    margin-bottom: 0
    box-shadow: none

.dc-header
    background: #66839E
    color: #FFFFFF
    letter-spacing: 0

thead.submit-batch-header > tr,
thead.dc-header > tr
    height: 49px

thead.submit-batch-header > tr > td,
thead.dc-header > tr > td
    height: inherit

thead.dcview-grid-header > tr,
thead.dc-header > tr
    height: 40px

thead.dcview-grid-header > tr > td,
thead.dc-header > tr > td
    height: inherit

/*
        Table Header
    */
.tbl-header
    background: #66839E
    color: #FFFFFF
    letter-spacing: 0


thead.tbl-header > tr
    height: 49px


thead.tbl-header > tr > td
    height: inherit

thead.tbl-header > tr > th
    border-right-color: #FFFFFF

thead.tbl-header > tr
    height: 40px


thead.tbl-header > tr > td
    height: inherit

.line-height-10px
    line-height: 10px !important
    letter-spacing: 0

.line-height-15px
    line-height: 15px !important
    letter-spacing: 0

thead.header-padding > tr > td
    height: inherit
    padding: 3px
    padding-top: 0

thead.header-govt-mbs > tr > td
    height: inherit
    padding: 4px 12px 4px 12px

tbody.govt-mbs-padding > tr > td
    padding: 8px 12px 8px 12px

.dc-text
    line-height: 1.4
    letter-spacing: 0.1px

.aec-text
    line-height: 1.4
    letter-spacing: 0.1px

.table-vertical-align > tbody > tr > td
    vertical-align: middle
    line-height: 1.3


.inner-addon
    position: relative

/* style glyph */
.inner-addon .glyphicon
    position: absolute
    padding-right: 8px
    padding-top: 4px
    pointer-events: none

.right-addon .glyphicon
    right: 0px

.right-addon input
    padding-right: 30px

table.scroll tbody,
table.scroll thead, table.scroll
    display: inline-block

table.scroll tbody
    max-height: 300px
    overflow-y: auto
    overflow-x: hidden

#ContactList .table.scroll tbody
    max-height: 376px

#servicingReleasedTransfer.table tr:focus
    outline: none

.table-no-border-right
    border-right: 0

.progress-bar-no-border
    border: 0
    margin-bottom: 0
    background-color: #D9D9D9
    height: 25px

.progress-bar-height-20px
    height: 20px

.progress-bar-height-40px
    height: 40px

input[type="file"]#Otherfiles,
input[type="file"]#Aecfiles,
input[type="file"]#hlcFiles,
input[type="file"]#batchFile,
input[type="file"]#batchAdditionalFile,
input[type="file"]#cebatchFile,
input[type="file"]#servicingFile,
input[type="file"]#bankReconciliationFile,
input[type="file"]#mpfServicingFiles,
input[type="file"]#bankPricingScheduleFile,
input[type="file"]#bankOneTimeScheduleFile,
input[type="file"]#pfiLevelAdjustmentFile,
.govtMbsInputFile
    display: none

.upload-btn-style
    padding: 6px 14px
    font-weight: bold
    line-height: 1.4
    cursor: pointer

.progress-bar-no-border span
    padding-right: 5px

.text-disabled-background
    background-color: #ffffff !important

.browse
    cursor: pointer
    margin: 0px

.browse-disabled
    cursor: not-allowed
    margin: 0px

@media (min-width: 992px)
    
    .dc-top-align-5px
        top: 0px

    .dc-top-align-22px
        top: 0px

@media (min-width: 1200px)
   
    .dc-top-align-5px
        top: 5px

    .dc-top-align-22px
        top: 22px

.single-upload-btn-style
    padding: 2px 10px 2px 10px
    font-weight: bold
    line-height: 1.4
    cursor: pointer

.position-absolute
    position: absolute

.position-relative
    position: relative

/*#region DCReport Width */

#dcReport > thead > tr > th
    text-align: center

#dcReport > thead > tr > td,
#dcReport > thead > tr > th,
#dcReport > tbody > tr > th,
#dcReport > tbody > tr > td
    padding: 4px

#dcReport > tfoot > tr > th
    padding-left: 4px
    padding-right: 4px
    padding-top: 8px
    padding-bottom: 10px

#dcReport td > div
    overflow: hidden
    text-overflow: ellipsis

#dcReport.table.scroll td:nth-child(1) > div
    width: 58px

#dcReport.table.scroll td:nth-child(2) > div
    width: 78px

#dcReport.table.scroll td:nth-child(3) > div
    width: 75px

#dcReport.table.scroll td:nth-child(4) > div
    width: 73px

#dcReport.table.scroll td:nth-child(5) > div
    width: 71px

#dcReport.table.scroll td:nth-child(6) > div
    width: 70px

#dcReport.table.scroll td:nth-child(7) > div
    width: 50px

#dcReport.table.scroll td:nth-child(8) > div
    width: 66px

#dcReport.table.scroll td:nth-child(9) > div
    width: 66px

#dcReport.table.scroll td:nth-child(10) > div
    width: 76px

#dcReport.table.scroll td:nth-child(11) > div
    width: 50px

#dcReport.table.scroll td:nth-child(12) > div
    width: 60px

#dcReport.table.scroll td:nth-child(13) > div
    width: 68px

#dcReport.table.scroll td:nth-child(14) > div
    width: 86px

#dcReport.table.scroll td:nth-child(15) > div
    width: 86px

#dcReport.table.scroll th:nth-child(1) > div
    width: 885px

#dcReport.table.scroll th:nth-child(2) > div
    width: 68px

#dcReport.table.scroll th:nth-child(3) > div
    width: 86px

#dcReport.table.scroll th:nth-child(4) > div
    width: 86px

#dcReport.table-bordered
    border: none
/*#endregion */


/*#region Tabular common Width */
#tabularReport
    width: 100%

#tabularReport > thead > tr > th
    text-align: center

#tabularReport > thead > tr > td,
#tabularReport > thead > tr > th,
#tabularReport > tbody > tr > th,
#tabularReport > tbody > tr > td
    padding: 4px

#tabularReport > tfoot > tr > th
    padding-left: 4px
    padding-right: 4px
    padding-top: 8px
    padding-bottom: 10px

#tabularReport td > div
    overflow: hidden
    text-overflow: ellipsis

#tabularReport.table.scroll td:nth-child(1) > div
    width: 58px

#tabularReport.table.scroll td:nth-child(2) > div
    width: 78px

#tabularReport.table.scroll td:nth-child(3) > div
    width: 75px

#tabularReport.table.scroll td:nth-child(4) > div
    width: 73px

#tabularReport.table.scroll td:nth-child(5) > div
    width: 71px

#tabularReport.table.scroll td:nth-child(6) > div
    width: 70px

#tabularReport.table.scroll td:nth-child(7) > div
    width: 50px

#tabularReport.table.scroll td:nth-child(8) > div
    width: 66px

#tabularReport.table.scroll td:nth-child(9) > div
    width: 66px

#tabularReport.table.scroll td:nth-child(10) > div
    width: 76px

#tabularReport.table.scroll td:nth-child(11) > div
    width: 50px

#tabularReport.table.scroll td:nth-child(12) > div
    width: 60px

#tabularReport.table.scroll td:nth-child(13) > div
    width: 68px

#tabularReport.table.scroll td:nth-child(14) > div
    width: 86px

#tabularReport.table.scroll td:nth-child(15) > div
    width: 86px

#tabularReport.table.scroll th:nth-child(1) > div
    width: 885px

#tabularReport.table.scroll th:nth-child(2) > div
    width: 68px

#tabularReport.table.scroll th:nth-child(3) > div
    width: 86px

#tabularReport.table.scroll th:nth-child(4) > div
    width: 86px

#tabularReport.table-bordered
    border: none
/*#endregion */

.right-inner-addon a
    position: absolute
    right: 0px
    padding: 9px 22px
    cursor: pointer
    text-decoration: none

.govt-mbs-add-file-style
    padding: 3px 14px
    font-weight: bold
    line-height: 1.4
    cursor: pointer

.table-border-radius
    border-radius: 4px

/*------OG Forms Styel ----- */



/* Tool tip on og forms position */
div#og1-form-contents > * span ~ div.tooltip
    left: -180px !important

div#og2-form-contents > * span ~ div.tooltip
    left: -180px !important

.og-form-header-content
    color: #6E6E6E
    font-size: 16px
    line-height: 22px
    padding-bottom: 20px

.og-form-header-box
    background: #B2E0F4
    color: #505050
    padding: 1px 10px
    border-bottom: 1px solid transparent
    border-top-left-radius: 3px
    border-top-right-radius: 3px
    line-height: 22px

.og-form-header-border
    border: 1px solid #D9D9D9

.og-form-text
    color: #6E6E6E
    font-size: 16px
    line-height: 22px

.og-form-help-icon
    color: #CA7700
    font-size: 22px
    line-height: 23px


.og-form-main-section-padding
    padding: 0px 50px
    background-color: #FAFBFC

.og-form-section-border
    background-color: #FFF
    border: 1px solid #E8EBF1

.og-form-help-text
    color: #CA7700
    font-size: 9px
    line-height: 13px
    margin-top: -10px

.og-form-text-center
    text-align: center

.og-form-section
    color: #0098DB
    line-height: 24px

.og-font-weight
    font-weight: normal

.og-form-save-btn
    min-width: 128px
    color: white
    background-color: #676767
    border: 1px solid #676767
    border-radius: 4px
    font-size: 16px
    line-height: 21px

.og-form-save-btn:hover
    color: white

.og-form-submit-btn
    min-width: 128px
    color: white
    background-color: #00AF3F
    border: 1px solid #00AF3F
    border-radius: 4px
    font-size: 16px
    line-height: 21px

.og-form-submit-btn:hover
    color: white

.og-form-ok-btn
    min-width: 80px
    color: #FFFFFF
    background-color: #676767
    border: 1px solid #676767
    border-radius: 4px
    font-size: 16px
    line-height: 21px

.og-form-pfistatus-border-bottom
    border-bottom: 1px solid #E8EBF1

.og-form-print
    color: #676767
    font-size: 22px
    line-height: 25px

.og-form-print-align
    top: 3px
    right: 15px
    cursor: pointer

.og-form-print-text
    color: #676767
    font-size: 9px
    line-height: 13px
    margin-top: -10px

.og-form-answer-textbox-height
    height: 100px
    font-size: 16px !important

.og-form-contact-admin-panel-border
    border: 1px solid #B19401

.og-form-contact-admin-panel-backgorund
    background-color: #B19401

.og-form-contact-admin-icon
    color: #FFFFFF
    font-size: 20px
    line-height: 20px

.og-form-contact-admin-header
    color: #6E6E6E
    font-size: 18px
    line-height: 24px

.og-form-contact-admin-text
    color: #6E6E6E
    font-size: 16px
    line-height: 22px

.og-form-contact-admin-row
    display: -ms-flexbox
    display: flex

.og-form-contact-admin-row > [class*='col-']
    display: -ms-flexbox
    display: flex
    -ms-flex-direction: column
    flex-direction: column

.og-form-contact-admin-email
    color: #CA7700

/*To display validation error right side of control - Start*/
.form-group-right.has-error
    position: relative

.og-form-pfi-status-bottom-border
    border-bottom: 1px solid #E8EBF1

.og-form-pfi-status-right-border
    border-right: 1px solid #E8EBF1

.pfi-status-font-size-title
    font-size: 17.9px

.margin-bottom-0px
    margin-bottom: 0px !important

.margin-top-0px
    margin-top: 0px !important
/*To display validation error right side of control - End*/

/*#region Submit Batch */

.submit-batch-box-header
    background: #B2E0F4
    color: #6E6E6E
    padding: 1px 10px
    border-bottom: 1px solid transparent
    border-top-left-radius: 3px

.submit-batch-border
    border: 1px solid #D9D9D9
    padding: 25px

.browse-button-background
    background-color: #6E6E6E !important
    padding-left: 15px !important
    padding-right: 15px !important

.browse-button-background-modal
    background-color: #6E6E6E
    padding-left: 15px !important
    padding-right: 15px !important

.modalList
    min-height: 250px
    max-height: 400px
    overflow: auto

table#viewdcList.table-bordered,
table#viewLprList.table-bordered,
table#mcList.table-bordered
    border: none

table#viewdcList,
table#viewLprList,
table#mcList,
#viewBatchLoanData
    max-width: 1186px
    width: 100%

#viewdcList > thead > tr > td,
#viewdcList > thead > tr > th,
#viewLprList > thead > tr > td,
#viewLprList > thead > tr > th,
#mcList > thead > tr > td,
#mcList > thead > tr > th,
#viewBatchLoanData > thead > tr > th
    text-align: center

#viewdcList > thead > tr > td,
#viewdcList > thead > tr > th,
#viewdcList > tbody > tr > th,
#viewdcList > tbody > tr > td,
#viewLprList > thead > tr > td,
#viewLprList > thead > tr > th,
#viewLprList > tbody > tr > th,
#viewLprList > tbody > tr > td,
#mcList > thead > tr > td,
#mcList > thead > tr > th,
#mcList > tbody > tr > th,
#mcList > tbody > tr > td
    padding: 4px 4px


#viewdcList > tfoot > tr > th,
#viewLprList > tfoot > tr > th,
#mcList > tfoot > tr > th
    padding-left: 4px
    padding-right: 4px
    padding-top: 8px
    padding-bottom: 10px

#viewdcList td > div,
#viewLprList td > div,
#mcList td > div
    overflow: hidden
    text-overflow: ellipsis

.submit-batch-header
    background-color: #66839E
    color: #FFFFFF

.dcview-grid-header
    background-color: #66839E
    color: #FFFFFF

.dcview-grid-header input[type=checkbox] ~ label:before
    color: #FFF !important

.dcview-grid-header td
    vertical-align: middle !important
    font-weight: bold

/*#endregion */

/*#region Batch Report */
#batchDetails.panel-heading,
#loanDetails.panel-heading,
#batchReports.panel-heading,
#batchStatus.panel-heading
    border: 1px solid #ddd
    border-radius: 0px

#batchStatus.table-bordered
    border: 1px solid #ddd

.light-green-background
    background: #B5F2CB

.green-background
    background: #00AF3F

#faCheck.fa-stack
    width: 3em

.light-red-background
    background: #f2cdcd

#loanDetailsBatch.table > tbody > tr > td
    padding: 8px

#loanDetailsBatch.table-bordered,
#loanDetailsBatch.table-bordered > tbody > tr > td
    border: 2px solid #ddd

.red-background
    background: #D52B1F

.yellow-background
    background: #b09900

.light-yellow-background
    background: #f1e9c5

.peach-color-background
    background: #F8EAE9

.fadeLight-yellow-background
    background: #FBFAAE


/*#endregion */

.og-form-pfistatus-border-bottom
    border-bottom: 1px solid #E8EBF1

.og-form-section-blue-border
    background-color: #E5F4FB
    border: 1px solid #0098DB

.og-form-save-submit
    font-weight: bold
    color: green

.border-nav-pills
    border: 2px solid #0098DB
    border-bottom: 0
    background: #B2E0F4

.border-nav-pills > li
    border-bottom: 2px solid #0098DB

/*#endregion */

/*messages*/

.messages-content-header-title
    font-size: 20px
    color: #6E6E6E

@media (min-width: 768px)
    .messages-content-list-item-panel
        height: 525px

    .msg-sidebar
        height: 525px



.messages-popover-title
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

.messages-popover-list-item
    overflow-y: hidden
    padding: 10px 20px
    max-height: 75px
    border: solid 1px #b5b6b3
    width: 100%

/*#messagePopoverList.messages-popover-list-item > div:hover, #messagePopoverList.messages-popover-list-item > div:focus, #messagePopoverList.messages-popover-list-item > div:visited*/
#messagePopoverList > div:hover, #messagePopoverList > div:focus
    text-decoration: none
    background-color: #0097DA
    color: #FFFFFF !important
    border-radius: 0
    outline: none

#messagePopoverList > div:hover .message-item-on-hover, #messagePopoverList > div:focus .message-item-on-hover
    text-decoration: none
    background-color: #0097DA
    color: #FFFFFF !important
    border-radius: 0
    outline: none

.messages-search-box-icon
    font-size: 22px
    color: #CA7700
    padding-top: 15px
    padding-right: 0px

.messages-popover-unread-count
    border-radius: 5px
    border: 1px solid white
    padding: 1px 8px
    background-color: #002F5F
    color: white

.messages-content-list-item
    overflow-y: hidden
    padding: 10px 0px
    height: 75px
    border: solid 1px #b5b6b3
    background-color: #F9F9F9

.messages-content-list-item.active
    background-color: white
    border-style: solid
    margin-right: -1px
    box-shadow: 1px 0px 15px #b5b6b3
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)
    -ms-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)"
    zoom: 1

.messages-content-list-item.active:after
    content: ''
    display: block
    position: absolute
    top: 27px
    left: 100%
    width: 0
    height: 0
    border-color: transparent transparent transparent white
    border-style: solid
    border-width: 10px

.messages-content
    overflow-y: hidden
    padding: 10px 18px

.messages-content-date
    padding: 5px 0px 0px 0px
    border-top-style: solid
    border-top-width: 1px
    border-top-color: #b5b6b3
    color: #676767
    box-sizing: border-box
    font-size: 10px

.messages-content-paragraph
    margin-top: 14px
    color: #676767
    font-size: 16px

/* enable absolute positioning */
.inner-addon
    position: relative

/* style glyph */
.inner-addon .glyphicon
    position: absolute
    padding: 5px
    pointer-events: none


/* add padding  */

@media screen
    #printSection
        display: none

@media print
    body *
        visibility: collapse

    #printSection, #printSection *
        visibility: visible

    .printHidden
        display: none !important

    #printSection
        position: absolute
        left: 0
        top: 5px

    .collapse
        display: block !important
        height: auto !important

    div#messageSection
        width: 100%

    div#messageSectio:last-child
        page-break-after: auto

    .padding-header-left
        padding-left: 0px !important
        padding-bottom: 20px

/*messages - end*/

ul.mc-detail-info
    background-color: #E5F4FB
    list-style: none
    padding-left: 0

ul.mc-detail-info ul
    padding-left: 0

ul.mc-detail-info li ul li
    display: inline-block

ul.mc-detail-info li ul li:nth-child(n+2)
    border-left-style: solid
    border-left-width: 1px
    display: inline-block
    padding-left: 5px
    line-height: 20px

ul.mc-detail-info span:nth-child(n+2)
    padding-left: 5px

/*#region Servicing Transfer */
#Advisory .modal-hr,
#create-lpr-cancel-id .modal-hr
    border-width: 5px 0 0 0
    border-top-color: #c80000

#Advisory .modal-body,
#create-lpr-cancel-id .modal-body
    padding: 15px

.continue-button
    background: #6E6E6E
    padding: 6px 25px

.row.vdivide [class*='divide']:not(:last-child):after
    background: #B2C0CF
    width: 1px
    content: ""
    display: block
    position: absolute
    top: 0
    bottom: 0
    right: 0
    min-height: 70px

/*#endregion */

/*#region Loan Presentment */

#newPfiloan.control-label
    padding-top: 4px

#divULI.form-group
    margin-left: -15px !important

#newPfiLoanNumber.form-control
    height: inherit
    padding: 4px 12px

#loanPresentmentFinder .form-control
    height: 2.7%
    padding: 3px 10px

#loanPresentmentFinder .control-label
    padding-top: 4px

#contactMailingList.table-bordered > tbody > tr > td
    border-right: 1px solid #ddd
    border-top: 0
    border-bottom: 0

#contactMailingList.table > tbody > tr > td
    padding: 8px 12px

#contactMailingList.table > thead > tr > td
    padding: 0 12px

#contactMailingList thead.submit-batch-header > tr
    height: 40px

#contactMailingList.table.scroll td:nth-child(1) > div
    width: 80px

#contactMailingList.table.scroll td:nth-child(2) > div
    width: 300px

#contactMailingList.table.scroll td:nth-child(3) > div
    width: 350px

#ContactList .modal-body
    padding-top: 5px

.send-email-button
    background: #676767
    padding-left: 20px
    padding-right: 20px
    color: #FFFFFF

#faLprSummary.fa-stack
    width: 4em
    height: 2.72em

.border-green
    border: 1px solid #00AF3F

.panel-heading-report
    background: #e5f4fb
    border: 1px solid #d9d9d9

@media (min-width: 992px)
    .margin-left-50-sm
        margin-left: -50px !important

.padding-top-22px
    padding-top: 22px

#newLoanPresentmentPanelId, #existingLoanPresentmentPanelId
    min-height: 360px

#printLpr .form-horizontal .form-group,
#createLpr .form-horizontal .form-group
    margin-right: 0
    position: relative

#createLpr label.select
    overflow: inherit

#createLpr label.select .help-tip
    right: -24px

#createLpr .form-group
    margin-bottom: 20px

#createLpr .form-control
    padding: 6px 10px

.faLprSummary
    float: left
    height: 60px !important

#averageApr.control-label,
#higherLoanStatus.control-label,
#supervisoryLicense.control-label,
#originationCompanyId.control-label,
#loanOriginator.control-label,
#appraiserLicense.control-label,
#guarantyissueDate.control-label,
#aus.control-label,
#monthsReservesClosing.control-label,
#totalEscrowmonthly.control-label,
#stabilityfund.control-label,
#streamlineindi.control-label,
#lblcoborrowerSsn.control-label,
#lblborrowerSSN.control-label,
#lblborrowerBusinessPh.control-label,
#lblborrowerHomePh.control-label,
#lblcoborrowerBusinessPh.control-label,
#lblcoborrowerHomePh.control-label,
#lblcoborrowerFICOSrc.control-label,
#lblPropertyTaxAmtAtClosing.control-label,
#lblHazardInsuranceAmtAtClosing.control-label,
#lblMortgageInsuranceAmtAtClosing.control-label,
#lblFloodInsuranceAmtAtClosing.control-label,
#lblAggregateAdjAmtAtClosing.control-label,
#lblOtherAmtAtClosing.control-label,
#lblOtherAmtDesc.control-label,
#lblOtherMonthlyDesc.control-label,
#lblAppraisalDocFileID.control-label
    padding-top: 0

#createLprPanel .select
    margin-bottom: 0px

.margin-top-22px
    margin-top: 22px

.margin-left-minus-30
    margin-left: -30px

.panel-heading-background-light
    background: #eff8fc

.panel-heading-background-blue
    background: #B2E0F4

.special-feature-code
    font-size: 12px
    position: absolute
    line-height: 34px
    left: 0
    width: 1rem
    text-align: right
    padding-right: 1px

#dvSpecialFeatureCode .form-control
    padding: 2px 4px
    margin: 0 0 0 .35rem
    width: 2.5rem

.placeholder-right::-webkit-input-placeholder
    text-align: right

.placeholder-right:-ms-input-placeholder
    text-align: right

/*#endregion */


@media print
    #btnPrintIcon
        display: none

/*#region Delivery Commitment */
.dcReductionTimeRemainingMessage
    color: #6E6E6E
    font-size: 20px


.dcReductionProgressStatus
    color: #6E6E6E
    font-size: 12px

#mc-list .modal-hr-border-top-color-1,
#dc-list .modal-hr-border-top-color-1,
#lpr-list .modal-hr-border-top-color-1
    border-color: #4CB6E5

#mc-list .modal-hr,
#dc-list .modal-hr,
#lpr-list .modal-hr
    border-width: 3px

#mc-list .modal-body,
#dc-list .modal-body,
#lpr-list .modal-body
    padding-top: 5px

/*#extendDeliveryCommitmentPanel .panel-header-1,*/
#extendDeliveryCommitmentDisplayPanel .panel-header-1,
#reduceDeliveryCommitmentDisplayPanel .panel-header-1
    padding: 8px 15px

/*#reduceDeliveryCommitmentPanel .panel-header-1
    padding: 8px 15px*/

#extendDeliveryCommitmentPanel .form-group,
#extendDeliveryCommitmentDisplayPanel .form-group
    margin-bottom: 20px

.hr-grey
    border-color: #c6c6c6

#NoteRates > .popover
    background-color: white
    color: black
    min-width: 250px

#NoteRates > .popover > .popover-inner > .popover-content
    padding-top: 0px

.border-bottom-dashed
    border-bottom: 1px dashed

#dc-confirmation-msg-with-failure-email-id.fa-stack,
#dc-confirmation-msg-with-success-email-id.fa-stack
    width: 2.5em

#dc-confirmation-msg-with-failure-email-id-for-new-dc.fa-stack,
#dc-confirmation-msg-with-success-email-id-for-new-dc.fa-stack
    width: 3.5em
    height: 2.5em

.dc-new-remaining-delivery-commitment-amount
    background-color: #E5F4FB
    height: 25px
    width: 180px
    left: 15px

.batch-selectall-cursor
    cursor: pointer


.batch-opensans-semibold
    color: #505050
    font-family: 'Open Sans SemiBold'

#tblNoteRatesList > .table > tbody > tr > td
    border-right: 1px solid #b5b6b3
    border-top: 0

#tblNoteRatesList > .table
    width: 100%
    max-width: 100%
    margin-bottom: 5px

#tblNoteRatesList > .table > thead > tr > td
    border-right: 1px solid #b5b6b3
    border-top: 0


.view-dc-left-center-noterate
    width: 55px
    margin-left: auto
    margin-right: auto

.dc-review-screen-border
    border: 1px solid #d9d9d8

.confirmation-text
    background: #F6F3E2
    border: 2px solid #ca7700
    column-count: 2
    color: #6E6E6E

.note-rate-highlight
    background-color: #e5f4f9
    border: 4px solid #183f74

.note-rate-highlight-left
    background-color: #e5f4f9
    border-left: 4px solid #183f74
    border-top: 4px solid #183f74
    border-bottom: 4px solid #183f74

.note-rate-highlight-right
    background-color: #e5f4f9
    border-right: 4px solid #183f74
    border-top: 4px solid #183f74
    border-bottom: 4px solid #183f74

.dc-email-cursor
    cursor: pointer

/*#endregion */

/*#region Pricing */
#srpPricingDetails > .table > tbody > tr > td,
#PricingGrid > .table > tbody > tr > td
    border: 1px solid #d9d9d8

#srpPricingDetails > .table > thead > tr > td,
#PricingGrid > .table > thead > tr > td
    border: 1px solid #d9d9d8

#srpPricingDetails > .table,
#PricingGrid > .table
    width: 100%
    max-width: 100%
    margin-bottom: 0px

.panel-info-pricing
    border: 1px solid #B2E0F4

.panel-info-pricing > .panel-heading
    color: #676767
    background-color: #B2E0F4

.panel-info-aec
    border: 1px solid #B2E0F4

.panel-info-aec > .panel-heading
    color: #676767
    background-color: #B2E0F4

#pricing-detail-products .panel-body,
#pricing-products .panel-body,
#srp-pricing .panel-body
    padding-top: 0
    padding-bottom: 0

#srp-detail-table.table > tbody > tr > td,
#view-pricing-table.table > tbody > tr > td
    vertical-align: middle

.row.pricing-panel-header
    background-color: #E5F4FB
    color: #676767
    border-bottom: 1px solid #B2E0F4
    margin-left: -15px
    margin-right: -15px

.pricing-border-top
    border-top: 1px solid #B2E0F4

.pricing-panel-body
    background-color: #F9FCFE
    color: #676767
    display: -ms-flexbox
    display: flex
    border-radius: 0px 0px 4px 4px

.pricing-panel-body-column
    -ms-flex-align: stretch
    align-items: stretch

.pricing-panel-body-column-validation
    background-color: #F8EAE9
    border: solid 1px #D52B1E
    height: 100%

.view-pricing-panel
    border: 1px solid #B2E0F4

.view-pricing-panel > .panel-heading
    color: #676767
    background-color: #E5F4FB

.pricing-view-table-header
    background-color: #66839E !important
    color: #FFFFFF


#srp-detail-table.table-bordered > thead > tr > td,
#view-pricing-table.table-bordered > thead > tr > td,
#view-pricing-table.table-bordered > tbody > tr:nth-child(3) > td
    border: 1px solid #606060

#srp-detail-table.table-bordered > tbody > tr > td,
#view-pricing-table.table-bordered > tbody > tr > td
    border: 1px solid #B8B8B8

#view-pricing-table.table-striped > tbody > tr:nth-child(3) > td
    background-color: #66839E !important

#view-pricing-table.table
    margin-bottom: 0

#pricing-save-selections-msg-id.fa-stack
    width: 2.5em

#print-download .btn
    padding: 0

#select-all input[type=checkbox] ~ label:before,
#select-all input[type=checkbox]:checked ~ label:before
    color: #CA7700

#current-pricing-container-id input[type=checkbox]:checked ~ label:before,
#mpfbank-accountingReports-container-id input[type=checkbox]:checked ~ label:before,
#download-lf-detail input[type=checkbox]:checked ~ label:before
    font-weight: bold

#current-pricing-container-id input[type=checkbox]:checked ~ label > span,
#mpfbank-accountingReports-container-id input[type=checkbox]:checked ~ label > span,
#download-lf-detail input[type=checkbox]:checked ~ label > span
    font-weight: bold

#archived-pricing-container-id input[type=checkbox]:checked ~ label:before
    font-weight: bold

#archived-pricing-container-id input[type=checkbox]:checked ~ label > span
    font-weight: bold

.archive-pricing-tab-selected-btn
    color: #FFFFFF
    background-color: #0098DB
    border-radius: 23px

.archive-pricing-tab-unselected-btn
    color: #B5B6B3
    background-color: #E8EbF1
    border-radius: 23px

#srpPricingForm .form-group
    margin-right: 0

#srpPricingDetails.panel-group .panel
    border-radius: 0

#srpPricingDetails .panel-body
    padding: 0

#srp-detail-table.table
    margin-bottom: 0

div#archived-pricing-view-product-container-id div#view-pricing-details
    min-height: 250px

div#archived-pricing-view-product-container-id div#view-pricing-details
    margin-bottom: 0px

/*#endregion */


/*#region Loan Funding */

#existingLoanFunding.form-group
    margin-left: 20px

#existingLoanFunding.has-error .control-label
    color: inherit

.lf-bottom-border
    border-bottom: 1px solid #979797

.lf-top-border
    border-top: 1px solid #979797
    padding-bottom: 5px
    width: 60%
    margin-left: auto
    margin-right: auto

.lf-summary
    color: #6E6E6E
    font-size: 16px

.lf-summary-text
    color: #676767
    font-size: 16px

/*#endregion */

.min-width-100px
    min-width: 100px

.min-width-110px
    min-width: 110px

.lf-left-border
    border-left: 1px solid #979797

.min-height-50px
    min-height: 50px

.min-height-45px
    min-height: 45px

.min-height-90px
    min-height: 90px

.batch-prices-header
    background-color: #66839E
    color: #FFFFFF

#batchPrices thead.batch-prices-header > tr > td > div
    padding: 5px

#batchPrices tbody > tr > td:nth-child(1) > div
    text-align: left

#batchPrices tbody > tr > td:nth-child(2) > div
    text-align: center

#batchPrices tbody > tr > td:nth-child(3)
    padding-bottom: 0px !important

#batchPrices thead > tr > td:nth-child(2)
    width: 130px

#batchPrices thead > tr > td:nth-child(3)
    padding-left: 25px

#batchPrices tbody > tr > td
    padding-left: 10px
    padding-right: 10px

#batchPrices thead.batch-prices-header > tr > td
    padding-left: 10px

#batchPrices tbody > tr > td:nth-child(3)
    width: 130px

.text-word-wrap
    word-wrap: break-word

#schedule
    height: 25px
    width: 120px

#pfiLoanChildDv
    position: relative
    float: left

.lf-note-rate-highlight-left
    background-color: #eff7f9
    border-left: 4px solid #183f74
    border-top: 4px solid #183f74
    border-bottom: 4px solid #183f74

.lf-note-rate-highlight-right
    background-color: #eff7f9
    border-right: 4px solid #183f74
    border-top: 4px solid #183f74
    border-bottom: 4px solid #183f74

.margin-bottom-minus-22px
    margin-bottom: -22px
/*batch 2.0*/
.margin-bottom-20px
    margin-bottom: 20px

.margin-bottom-10px
    margin-bottom: 10px
/*batch 2.0*/
.margin-bottom-17px
    margin-bottom: 17px

.margin-left-minus-12px
    margin-left: -12px

#recentLoan div.tooltip-inner
    min-width: auto
    max-width: 100%
    white-space: nowrap

#subProductDiv > .tooltip > .tooltip-inner
    min-width: 325px
    max-width: 325px

#subProductBEDiv > .tooltip > .tooltip-inner
    min-width: 325px
    max-width: 325px

#WarningMessage > .tooltip > .tooltip-inner
    min-width: auto
    max-width: 100%
/*batch 2.0*/

.btn-batch-browse
    height: 34px !important
    border-right: 1px solid #ccc !important
    border-top-right-radius: 4px !important
    border-bottom-right-radius: 4px !important

.borrRaceHelpicon + .help-tip
    right: 25px

.pricingScheduleDate
    padding-left: 38px !important

.dcTrBackgroundColor
    background-color: #66839E
    letter-spacing: 0

.trBackgroundColor
    background-color: #66839E
    letter-spacing: 0

.white-space-nowrap
    white-space: nowrap

.lpr-borrower-race-validation
    background-color: #F8EAE9
    border: solid 1px #D52B1E
    height: 100%

.checkbox-list-validation
    background-color: #F8EAE9
    border: solid 1px #D52B1E
    height: 100%

.dcFees-Background
    background-color: #E5F4FB

/*#region Access Denied */


.modal-hr-border-top-color-3
    border-top-color: #0097DA

#tabStyling input:focus + label,
#tabStyling input:hover + label
    background-color: #e5f4fb
    /*border: 1px gray solid
                width: 10em*/

#Anchorsuperscript a
    border-bottom: 1px solid
    display: inline-block
    line-height: 15px
    margin: 2px 0
    text-decoration: none

.textbox-400
    width: 400px !important

.btn-style-border
    border: 2px solid #ddd
    border-radius: 4px

.col-style-border
    border: 2px solid #ddd

#viewBatchLoanData td > div
    overflow: hidden
    text-overflow: ellipsis

#viewBatchLoanData > tbody > tr > td
    padding-bottom: 4px
    padding-top: 4px
    padding-left: 0px
    padding-right: 0px

.name-style
    overflow: hidden
    resize: none

.font-size-16px
    font-size: 16px !important

.max-height-400px
    max-height: 400px !important

.max-height-300px
    max-height: 300px !important

.padding-bottom-25px
    padding-bottom: 25px

.text-paf-color
    color: #336699

.hr-pricing-preview
    margin-top: 5px !important
    margin-bottom: 0px !important
    border-top: 3px solid black !important

.margin-top-10px
    margin-top: 10px

.dc-detail-footer-row
    color: #FFFFFF
    font-weight: bold

.accounting-report-panel-body
    background-color: #eff7f9
    color: #676767

.dc-report-panel-top-border
    border-top: 1px solid #B2E0F4 !important

.width-200px
    width: 200px

.width-380px
    width: 380px

.active-dc-report-table
    background-color: white
    border-right-color: white !important
    border-left-color: white !important
    border-bottom-color: white !important
    line-height: 50px
    padding-top: 10px

.dc-report-table-bottom-border-white
    border-bottom-color: white !important



div#treasury-viw-lf-content > * span ~ div.tooltip
    margin-left: 20px !important

.padding-bottom-12px
    padding-bottom: 12px !important

.width-30-char
    width: 180px
    white-space: nowrap
.float-right
    float: right !important
/*#region Access Denied  */

.text-decoration-underline
    text-decoration: underline

.sales-tools-hyperlink > a:hover,
.sales-tools-hyperlink > a:active,
.sales-tools-hyperlink > a:focus,
.sales-tools-hyperlink > a:visited
    text-decoration: none

/*PBI-119171*/
.margin-top-minus-5px
    margin-top: -5px !important

/*Batch 2.0*/
.padding-left-3px
    padding-left: 3px !important


.overflow-x
    overflow-x: auto

.overflow-y
    overflow-y: auto

.margin-bottom--5px
    margin-bottom: -5px

.border-3px-solid-gray
    border: 2px solid gray
    border-bottom: 3px solid gray !important

.border-2px-solid-gray
    border: 2px solid gray

.border-right-2px-solid-gray
    border-right: 2px solid gray !important

.table#view-schedule-table > tbody > tr > td > div#pfi-ContactNumber > div > span.btn
    padding: 2px 12px
    height: 26px

.table#view-schedule-table > tbody > tr > td > div#pfi-ContactNumber
    height: 26px

.table#special-schedule-success-table > tbody > tr > td,
.table#special-schedule-success-table > thead > tr > th,
.table#view-schedule-table > tbody > tr > td,
.table#view-schedule-table > thead > tr > th,
.table#active-special-schedule-table > tbody > tr > td,
.table#active-special-schedule-table > thead > tr > th
    vertical-align: middle
    text-align: center
    padding-left: 5px !important
    padding-right: 5px !important

/*PBI-157070*/
.emailfontsize
    font-size: 12px

.specialschedule-preview-scroll
    overflow-y: scroll
    overflow-x: scroll
    max-height: 560px
    min-height: 560px

.hlbpfi-td-width
    max-width: 200px
    word-wrap: break-word

.hlbpfi-td-chkwidth
    width: 75px

.text-align-center
    text-align: center

.max-width-80px
    max-width: 80px
    min-width: 80px

.max-width-85px
    max-width: 85px
    min-width: 85px

.max-width-115px
    max-width: 115px
    min-width: 115px

#specialScheduleToolTip .tooltip-inner
    min-width: 370px !important

.container
    margin: 0 auto
    max-width: 960px

.container > hr
    margin: 30px 0

.container .credit
    margin: 20px 0

.wrapper
    min-height: 100%
    height: auto !important
    height: 100%
    margin: 0 auto -65px
    background-color: white
    max-width: 960px

.footer
    height: 65px

/*  validation styles */
.font-bold
    font-weight: bold !important
