$MPFMediumBlue:#0098DB
$MPFDarkBlue:#002F5F
$LightBlue: #B2E0F4
$DarkGray:#676767

$Gold:#F5D000
$Orange:#F59B00
$PaleBlue: #E5F3FB
$Slate:#66839E
$MediumGray:#B5B6B3
$LightGray: #F9F9F9
$LightestGray: #F2F2F2
$OffWhite: #F8F8F8

$Purple:#F5D000
$DarkPurple:#F59B00
$DarkGold:#E5F4FB
$DarkOrange:#66839E
$Green:#00AF3F

$Red:#d52b1e
$DarkestGray:#F5D000
$Black:#000000	

$MPFDarkOrange: #CA7700 
$White:#FFFFFF

// Overriding Bootstrap variables here
$enable-validation-icons: false
$success: #28A745
$min-contrast-ratio: 3
$enable-negative-margins: true

.pageHeader
    font-family: Helvetica
    font-weight: Bold
    font-size: 25px
    text-align: left
    color: $MPFDarkBlue

.summaryHeader
    font-family: Open Sans Bold
    font-size: 20px
    text-align: left
    color: $MPFMediumBlue

.SubHeader
    font-family: Open Sans Bold
    font-size: 16px
    text-align: left
    color: $DarkGray
    background-color: $PaleBlue

.tableHeader
    font-family: Open Sans Bold
    font-size: 14px
    text-align: left
    color: $DarkGray

.fieldLabel
    font-family: Open Sans SemiBold
    font-size: 16px
    color: $DarkGray

.defaultText
    font-family: Open Sans
    font-size: 16px
    color: $DarkGray

.defaultGrayText
    font-family: Open Sans
    font-size: 16px
    color: $DarkestGray

.lightGrayText
    font-family: Open Sans
    font-size: 16px
    color: $MediumGray

.tableText
    font-family: Open Sans
    font-size: 14px
    color: $DarkGray

.hyperLink
    font-family: Open Sans
    color: $MPFMediumBlue
    text-decoration: Underline

.hyperLinkNoUnderLine
    font-family: Open Sans
    color: $MPFMediumBlue
    text-decoration: none

.hyperLinkAlt
    font-family: Open Sans
    color: $DarkGray
    text-decoration: Underline

.breadcrumbs
    font-family: Open Sans
    font-size: 14px
    color: $MediumGray
    
.breadcrumbsAccent
    font-family: Open Sans Bold
    font-size: 14px
    color: $MPFDarkBlue

.darkButton
    background-color: $MPFDarkBlue
    border-radius: 4px
    width: 360px
    height: 36px
    overflow: hidden
    font-size: 16px
    font-weight: 700
    color: #ffffff

.lightButton
    background-color: $DarkGold
    border-radius: 4px
    width: 360px
    height: 36px
    overflow: hidden
    font-size: 16px
    font-weight: 700
    color: $MPFMediumBlue

.sectionHeader
    font-size: 16px
    font-weight: 700
    color: $MPFDarkBlue   

.font-dark-blue
    color: $MPFDarkBlue

.preWhiteSpace
  white-space: pre

    
@media print
     
  *,
  *:before,
  *:after 
    color: #000 !important
    text-shadow: none !important
    background: transparent !important
    -webkit-box-shadow: none !important
            box-shadow: none !important
  
  a,
  a:visited 
    text-decoration: underline
  
  a[href]:after 
    content: " (" attr(href) ")"
  
  abbr[title]:after 
    content: " (" attr(title) ")"
  
  a[href^="#"]:after,
  a[href^="javascript:"]:after 
    content: ""
  
  pre,
  blockquote 
    border: 1px solid #999

    page-break-inside: avoid
  
  thead 
    display: table-header-group
  
  tr,
  img 
    page-break-inside: avoid
  
  img 
    max-width: 100% !important
  
  p,
  h2,
  h3 
    orphans: 3
    widows: 3
  
  h2,
  h3 
    page-break-after: avoid
  
  .navbar 
    display: none
  
  .btn > .caret,
  .dropup > .btn > .caret 
    border-top-color: #000 !important
  
  .label 
    border: 1px solid #000
  
  .table 
    border-collapse: collapse !important
  
  .table td,
  .table th 
    background-color: #fff !important
  
  .table-bordered th,
  .table-bordered td 
    border: 1px solid #ddd !important

  .visible-print 
    display: none !important

@media print 
  .visible-print 
    display: block !important
  
  table.visible-print 
    display: table !important
  
  tr.visible-print 
    display: table-row !important
  
  th.visible-print,
  td.visible-print 
    display: table-cell !important
  

.visible-print-block 
  display: none !important

@media print 
  .visible-print-block 
    display: block !important
  

.visible-print-inline 
  display: none !important

@media print 
  .visible-print-inline 
    display: inline !important
  

.visible-print-inline-block 
  display: none !important

@media print 
  .visible-print-inline-block 
    display: inline-block !important
  

@media print 
  .hidden-print 
    display: none !important
.printHidden
    display: block
  
li.dropdown-item>a
  text-decoration: none
  display: block
  width: 100%
  height: 100%

#messages .popover-body
  display: contents
