@font-face
  font-family: 'Open Sans'
  font-style: normal
  font-weight: 400
  src: url("assets/fonts/opensans/OpenSans-Regular.ttf") format("truetype")

@font-face
  font-family: 'Open Sans Bold'
  font-style: bold
  font-weight: 700
  src: url('assets/fonts/opensans/OpenSans-Bold.ttf')

@font-face
  font-family: 'Open Sans SemiBold'
  font-style: normal
  font-weight: 600
  src: url('assets/fonts/opensans/OpenSans-SemiBold.ttf')

@import 'fhlb-common'
@import 'empf'
@import 'base'
@import 'bootstrap/scss/bootstrap.scss'
@import 'ngx-toastr/toastr-bs5-alert'
@import '@ng-select/ng-select/themes/default.theme.css'

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label
  min-height: 0px
  border-radius: 15px
  background-color: #66839E
  color: #FDFEFE

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left
  display: none

.card-heading-background-blue
  background-color: $LightBlue

.card-heading-background-lightblue
  background-color: $PaleBlue

body
  font-family: 'Open Sans'
  background: #002F5F

.font-light-blue
  color: $MPFMediumBlue

.font-green
  color: $green

.background-light-blue
  background: $PaleBlue
  &:hover
    background: $PaleBlue

// Temporary styles to match BS 3 styles
.navbar
  padding: 0

a, a:not([href]),.nav-link
  color: #337ab7
  text-decoration: none

a:not([href]):hover
  color: #337ab7
  text-decoration: underline
  cursor: pointer

.dropdown-toggle::after, .dropdown-toggle-select-element::after
  content: none

.nav-justified .nav-item
  flex-basis: inherit

.dropdown-item
  padding: 10px 14px

.dropdown-menu > li > a
  padding: 0

.dropdown-menu-sites-menu > li > a
    padding: 10px 7px !important
    
.nav-justified > .dropdown .dropdown-menu .dropdown-submenu .dropdown-menu
  left: 100%
  margin-top: 0px !important
  width: auto
  border-radius: 0 !important
    

.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus
  background-color: $PaleBlue
  cursor: pointer

.dropdown-item.active > a, .dropdown-item:active > a, .dropdown-item:hover > a, .dropdown-item:focus > a
  color: $MPFDarkBlue
  font-family: 'Open Sans SemiBold'
  display: block
// End Temporary styles to match BS 3 styles

div.modal-dialog
  display: inherit

.centeredSchedule .modal-dialog
  display: inherit
  margin-top: 0px 

.modal-content
  border-radius: 0

.noUnderline
  text-decoration: none

.table
  --bs-table-striped-bg: transparent
  thead
    th
      vertical-align: middle

.toast
  opacity: 1 !important

.toast-container
  z-index: 1056 !important

.toast-container.toast-top-center .ngx-toastr
  width: 835px !important

.toast-container .ngx-toastr
  box-shadow: none !important
  opacity: 1 !important
  cursor: default !important
  padding-top: 15px !important
  padding-bottom: 0px !important
  padding-left: 30px !important
  padding-right: 30px !important

.toast-container .toastr-command
  cursor: pointer !important


.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .form-select:invalid, .form-select.is-invalid
  background-image: none

select.was-validated select.form-select:invalid, select.form-select.is-invalid
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIBAMAAAA2IaO4AAAAHlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC3KG9qAAAACXRSTlMABAcIEi6ytMBZALYlAAAAJElEQVQIHWMAA2FjYwWGzJkzpzBIzJwZwMDYOZWBgUE8gAEBAIuHBemYSa8PAAAAAElFTkSuQmCC)
    background-repeat: no-repeat
    background-position: calc(100% - 5px)
    background-size: auto


.form-group .help-block
  display: table

.form-control.ng-valid, .form-select.ng-valid
  border-color: #ced4da !important

.was-validated .form-control.ng-valid:focus, .was-validated .form-select.ng-valid:focus, input.form-control:valid:focus
  border-color: #80bdff !important
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25) !important
  background-image: none !important

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .form-select:valid, .form-select.is-valid
  background-image: none

.was-validated 
  .form-control.is-invalid, .form-select.is-invalid
    border: 1px solid #dc3545 !important

.was-validated .form-check-input:valid ~ .form-check-label
  color: inherit

.was-validated select.form-select.ng-invalid
  border-color: #dc3545 !important
  padding-right: calc(1.5em + 0.75rem)
  background-image: none
  background-repeat: no-repeat
  background-position: center right calc(0.375em + 0.1875rem)
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)


.hr-gray-border
  border-bottom: 1px solid $MediumGray

.background-pale-blue
  background-color: $PaleBlue

.background-light-gray
  background-color: $LightestGray

.background-off-white
  background-color: $OffWhite

.btn-dark-blue
  background-color: $MPFDarkBlue
  border-radius: 4px
  color: white
  border: $MPFDarkBlue

.btn-light-blue
  background-color: $PaleBlue
  border-radius: 4px
  color: $MPFMediumBlue
  border: $PaleBlue

// tooltip classes start
.bs-tooltip-top .arrow::before
  border-top-color: #CA7700 !important
  top: 1px

.bs-tooltip-bottom .arrow::before
  border-bottom-color: #CA7700 !important
  bottom: -1px

.tooltip.show
  opacity: 1

.tooltip .arrow
  bottom: 0px !important
  margin-bottom: 2px !important
  border-width: 10px 4px 0
  border-top-color: #CA7700 !important

.tooltip.bottom .arrow:after
  border-bottom-color: white
  border-top-color: transparent
  border-right-color: transparent
  border-left-color: transparent
  content: ""
  border-width: 0 3px 7px
  position: absolute
  border-style: solid
  margin-top: 6px
  margin-left: -3px

.bs-tooltip-top  .arrow:after
  border-top-color: white
  border-bottom-color: transparent
  border-right-color: transparent
  border-left-color: transparent
  content: ""
  border-width: 4px 4px 4px
  position: absolute
  border-style: solid
  margin-left: 2px

.bs-tooltip-bottom  .arrow:after
  border-top-color: transparent
  border-bottom-color: white
  border-right-color: transparent
  border-left-color: transparent
  content: ""
  border-width: 4px 4px 4px
  position: absolute
  border-style: solid
  margin-left: 2px

.tooltip-inner
  max-width: 215px
  min-width: 215px
  padding: 3px 5px
  color: black
  text-align: center
  background-color: white
  border-radius: 4px
  border: 2px solid #CA7700

// tooltip classes end

//Sort classes start 
.sort-asc:before
  content: "\f0de" !important

.sort-desc:before
  content: "\f0dd" !important

.sort-header:hover:before
  content: "\f0dc" !important

.sort-header.empty:hover:before
  content: "\00a0" !important

.sort-header
  padding-bottom: 3px
  text-transform: uppercase

.sort-header:before
  font-family: "FontAwesome"
  content: "\00a0"
  display: block
  text-align: center
  width: 100%

//Sort classes end

.font-color-dark-orange
  color: $MPFDarkOrange !important

div.sticky
  position: -webkit-sticky
  position: sticky
  top: 0
  z-index: 999

.btn-no-left-border
  border: 1px solid #ced4da
  border-left: 0

.no-right-border
  border-right: 0

.invalid-btn-border
  border-color: $Red !important

.dropdown-menu
  li
    padding: 10px 7px

.dropdown-submenu-arrow-right
  margin-right: 0px

li[class="nav-item"]
  color: transparent !important
  a
    padding-left: 0px
    padding-right: 0px

li[class="nav-item active"]
  a
    padding-left: 0px
    padding-right: 0px

.dark-modal .modal-dialog
  padding-top: 0px

.successmsg .modal-dialog
  padding-top: 60px
  max-width: 45vh

.warningmsg .modal-dialog
  margin-top: 220px
  max-width: 45vh

.importearlycheckrules-modal .modal-dialog
  margin-top: 150px
  max-width: 45vh

.warningmsg .modal-dialog
  margin-top: 220px
  max-width: 45vh

.importmpfdirectfunding-modal .modal-dialog
  margin-top: 150px
  max-width: 380px

.centered-modal .modal-dialog
  padding-top: 40vh
  text-align: center

.lpr-modal .modal-dialog
  max-width: 351px

.lprfund-modal .modal-dialog
  max-width: 425px

.reduceDC-modal .modal-dialog
  max-width: 440px  

.importearlycheckrules-modal .modal-dialog
  margin-top: 150px
  max-width: 400px

.dc-email-modal .modal-dialog
  max-width: 650px

.price-against-schedule-modal .modal-dialog
  max-width: 820px

.btn-danger
  background-color: $Red
  border-color: $Red

.margin-top-20px
  margin-top: -20px !important

.tabStyling input:focus + label,

.tabStyling input:hover + label
  background-color: #e5f4fb

.disabled
  pointer-events: none

.border-red
  border: solid 1px $Red

.btn-dark-grey
  background: $DarkGray
  border: $DarkGray
  color: $White

.warningmsgDcExtend .modal-dialog
  margin-top: 220px
  max-width: 350px
  margin-top: 150px

.update-rule-modal .modal-header .modal-title
  padding-left: 5px

.long-break-toast
  white-space: pre-line
  word-break: break-all

.file-Type
  background-color: #fff !important
  cursor: not-allowed

.reverse-loan-funding-modal .modal-dialog
  max-width: 600px
  margin-top: 220px

.italic-style
  font-style: italic!important

.centered-reverse-modal .modal-dialog
  margin-top: 150px
  max-width: 370px

.divide-two
    columns: 2

.form-control:disabled.is-invalid, .form-select:disabled.is-invalid
  border-color: #ced4da !important
  
.panel-body 
    padding: 15px

.panel-title 
    height: 40px
    padding-left: 10px
    padding-top: 5px

@media print
     
  *,
  *:before,
  *:after 
    color: #000 !important
    text-shadow: none !important
    background: transparent !important
    -webkit-box-shadow: none !important
            box-shadow: none !important
  
  a,
  a:visited 
    text-decoration: underline
  
  a[href]:after 
    content: " (" attr(href) ")"
  
  abbr[title]:after 
    content: " (" attr(title) ")"
  
  a[href^="#"]:after,
  a[href^="javascript:"]:after 
    content: ""
  
  pre,
  blockquote 
    border: 1px solid #999

    page-break-inside: avoid
  
  thead 
    display: table-header-group
  
  tr,
  img 
    page-break-inside: avoid
  
  img 
    max-width: 100% !important
  
  p,
  h2,
  h3 
    orphans: 3
    widows: 3
  
  h2,
  h3 
    page-break-after: avoid
  
  .navbar 
    display: none
  
  .btn > .caret,
  .dropup > .btn > .caret 
    border-top-color: #000 !important
  
  .label 
    border: 1px solid #000
  
  .table 
    border-collapse: collapse !important
  
  .table td,
  .table th 
    background-color: #fff !important
  
  .table-bordered th,
  .table-bordered td 
    border: 1px solid #ddd !important

  .visible-print 
    display: none !important

@media print 
  .visible-print 
    display: block !important
  
  table.visible-print 
    display: table !important
  
  tr.visible-print 
    display: table-row !important
  
  th.visible-print,
  td.visible-print 
    display: table-cell !important
  

.visible-print-block 
  display: none !important

@media print 
  .visible-print-block 
    display: block !important
  

.visible-print-inline 
  display: none !important

@media print 
  .visible-print-inline 
    display: inline !important
  

.visible-print-inline-block 
  display: none !important

@media print 
  .visible-print-inline-block 
    display: inline-block !important
  

@media print 
  .hidden-print 
    display: none !important
.printHidden
    display: block
  
.btn-secondary
    background-color: #666666
	
.btn-secondary:hover
    color: #333
    background-color: #e6e6e6
    border-color: #adadad  

ng-select
    font-size: 14px !important

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked
    color: white
    background-color: #428bca !important

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked
    background-color: #66afe9 !important
    color: white
  
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option
    padding: 4px 10px

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label
    font-weight: 400 !important

.ng-invalid
    border-radius: 4px

.was-validated ng-select.ng-invalid.ng-untouched .ng-select-container
  border-color: #dc3545 !important
  background-image: none
  background-repeat: no-repeat
  background-position: center right calc(0.375em + 0.1875rem)
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)

ng-select.ng-invalid.ng-touched .ng-select-container
  border-color: #dc3545 !important
  background-image: none
  background-repeat: no-repeat
  background-position: center right calc(0.375em + 0.1875rem)
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)

input.ng-invalid.ng-touched 
  border: 1px solid #dc3545 !important  

textarea.ng-invalid.ng-touched 
  border: 1px solid #dc3545 !important  

.btn-default:hover
  color: #333 !important
  background-color: #e6e6e6 !important
  border-color: #adadad !important

ngb-datepicker.dropdown-menu
  background-color: white !important
  border: 1px solid #D3D3D3 !important

.warningmsgDcExtend .modal-dialog
  margin-top: 220px
  max-width: 350px
  margin-top: 150px

.update-rule-modal .modal-header .modal-title
    padding-left: 5px
    border: 1px solid #D3D3D3 !important

ngb-datepicker.dropdown-menu.show
  min-width: auto !important

.ngb-dp-month:last-child .ngb-dp-week
  padding-right: .1rem

ng-select.ng-select-opened .ng-placeholder
  display: block !important

ng-select.ng-select-opened .ng-value
  display: none !important
  border: 1px solid #D3D3D3 !important

.toast-error
  border: none
  
.download-modal-width-380px .modal-dialog
  width: 380px
  padding-top: 38vh

.dc-not-completed-modal-325px .modal-dialog
  width: 325px
  padding-top: 25vh

.review-page-modal .modal-dialog
  min-width: 1280px
  max-width: 1280px
  margin-top: 0px !important

.active-centered-modal .modal-dialog
    min-width: 1182px !important
    max-width: 1182px !important
    padding-top: 0px !important

.centered-modal-DcEmail .modal-dialog
   max-width:800px !important
   min-width:800px !important
   padding-top: 25vh !important

.centered-modal-LF .modal-dialog
   max-width:800px !important    
   min-width:800px !important
   padding-top: 2vh !important

.centered-modal-emailpopup .modal-dialog
  max-width: 325px !important
  min-width: 325px !important
  padding-top: 45vh !important

.modal-width-650px > .modal-dialog 
  width: 650px
  padding-top: 28vh

.ngb-dp-day.disabled
  background-color: #B5B6B3
  border: 1px solid white
  cursor: not-allowed !important
  pointer-events: all !important

.ngb-dp-day .btn-light
  font-size: 13px !important

div.ngb-dp-day.ngb-dp-today > div
  background-color: #0097DA  !important
  opacity: 1 !important
  color: #e6e6e6 !important

ngb-datepicker .was-validated .form-select:valid, ngb-datepicker .form-select.is-valid
  border-color: rgb(206, 212, 218) !important
  padding-right: 0 !important
  background: none !important

input[role=combobox]:disabled 
  cursor: not-allowed !important

input[type=text]:disabled
  cursor: not-allowed !important
  
ng-select.ng-select-filtered .ng-placeholder
  color: transparent !important
  padding-left: 5px

.long-break-toast
  white-space: pre-line
  word-break: break-all

.file-Type
  background-color: #fff !important
  cursor: not-allowed

.reverse-loan-funding-modal .modal-dialog
  max-width: 600px
  margin-top: 220px

.italic-style
  font-style: italic!important

.centered-reverse-modal .modal-dialog
  margin-top: 150px
  max-width: 370px

.divide-two
    columns: 2

// merged from angjsUpgrade 
.width-838px
    width: 838px 

.max-height-376px
  max-height: 376px

ng-dropdown-panel
  width: auto !important
  min-width: 100% !important

.ng-dropdown-panel.ng-select-top
  width: auto !important

.padding-left-40px
  padding-left: 40px !important

.padding-right-40px
  padding-right: 40px !important

.nav > li 
    position: relative 
    display: block

.padding-left-30px
  padding-left: 30px

.padding-left-20px
  padding-left: 20px

.padding-left-60px
  padding-left: 60px !important

.padding-left-45px
  padding-left: 45px !important

.dropdown-item-sites-menu
  padding:0px !important

.mpfdirectconfirmation-modal .modal-dialog
  padding-top: 30vh
  text-align: center
  max-width: 325px

.mpfxtraconfirmation-modal .modal-dialog
  padding-top: 30vh
  text-align: center
  max-width: 600px 

.cursor-to-pointer
  cursor: pointer !important

.centered-modal-mfa .modal-dialog
  padding-top: 10vh
  text-align: center

.confirmation-submit-og .modal-dialog
  width: 325px !important
  height:148px !important
  padding-top: 35vh

.action-button-container .btn
  width: 100%

.card-header:first-child
    border-radius: 0
    border-bottom: 0
    padding: 0.5em

@for $i from 1 through 12
  .input-group.col-#{$i}
    width: percentage(divide($i, 12))

div.radio-check-input
  width: auto
  > div
    flex: 0 1 0
    text-wrap: nowrap
    width: auto
    
.table thead th
  padding-left: 13px

select.form-select
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important
  background-size: 12px !important
  background-position: calc(100% - 5px) !important

.row.justify-content-center ngb-pagination
  width: unset !important

.page-link:focus
  --bs-pagination-focus-bg: transparent

.jumbotron
  background-color: #e9ecef

.was-validated ngb-datepicker-navigation-select .form-select:valid
  border-color: #ced4da !important

.table-bordered 
  border: 1px solid #dee2e6

ngb-datepicker-navigation-select>.form-select
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px !important

.btn:focus, .btn.focus
    outline: 0
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
    
.bg-info, .text-bg-info
  background-color: #17a2b8 !important

.was-validated .form-control:valid
  border: 1px solid #ced4da

.btn:disabled, .btn.disabled
  pointer-events: all !important

.table-bordered th, .table-bordered td 
    border: 1px solid #dee2e6

.page-link 
    border: 1px solid #dee2e6 !important

.form-control[readonly] 
    background-color: #e9ecef

.table-striped > tbody > tr:nth-of-type(odd) > * 
    color: unset
